import { useLocation, useNavigate } from 'react-router-dom'
import { useLayoutEffect } from 'react'
import { pocketbase } from '../lib/config'
import { Alert, AlertTitle, Button, Container } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const redirectTo = location.pathname
  const hasAccess = pocketbase.authStore.isValid
  const navigateTo =
    '/login?redirected=true' + (redirectTo ? `&redirectTo=${redirectTo}` : '')

  useLayoutEffect(() => {
    if (!hasAccess) {
      navigate(navigateTo)
    }
  }, [])

  if (!hasAccess) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Alert
          severity="error"
          action={
            <Button component={RouterLink} to="/login">
              Login
            </Button>
          }
        >
          <AlertTitle>Login Required</AlertTitle>
          Please log in to continue.
        </Alert>
      </Container>
    )
  }

  return <>{children}</>
}

export default ProtectedRoute
