import { useQuery } from '@tanstack/react-query'
import { pocketbase } from '../lib/config'
import { Folder } from '../types/global'

const fetchFolders = async () => {
  return await pocketbase.collection('folders').getFullList<Folder>(200, {
    sort: '-created',
  })
}

const useFolders = (params?: { enabled?: boolean }) => {
  const queryKey = ['folders']

  return useQuery(queryKey, fetchFolders, {
    enabled: params?.enabled,
  })
}

export default useFolders
