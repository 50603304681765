import { useQuery } from '@tanstack/react-query'
import { pocketbase } from '../lib/config'
import { Tag } from '../types/global'

const fetchTags = async () => {
  return await pocketbase.collection('tags').getFullList<Tag>()
}

export interface CreateTagData {
  user: string
  name: string
}

export interface UpdateTagData {
  name?: string
}

export default (params?: { enabled?: boolean }) => {
  const queryKey = ['tags', params]

  return useQuery(queryKey, () => fetchTags(), {
    enabled: params?.enabled,
  })
}

export const createTag = async (data: CreateTagData) => {
  return await pocketbase.collection('tags').create<Tag>(data)
}

export const updateTag = async (id: string, data: UpdateTagData) => {
  return await pocketbase.collection('tags').update<Tag>(id, data)
}

export const deleteTag = async (id: string) => {
  return await pocketbase.collection('tags').delete(id)
}
