import { useQuery } from '@tanstack/react-query'
import { pocketbase } from '../lib/config'
import { Collection } from '../types/global'

const fetchCollections = async ({ queryKey }: any) => {
  const { folderId, sort } = queryKey[1]
  return await pocketbase
    .collection('collections')
    .getFullList<Collection>(200, {
      filter: `folder="${folderId}"`,
      sort: sort ?? '-name',
    })
}

const useCollections = (params: {
  folderId?: string
  sort?: string
  enabled?: boolean
}) => {
  const { folderId, sort, enabled } = params
  const queryKey = ['collections', { folderId, sort }]

  return useQuery(queryKey, fetchCollections, {
    enabled: enabled ?? !!folderId,
  })
}

export default useCollections
