import { useState } from 'react'
import { styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import MuiDrawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import QuizIcon from '@mui/icons-material/Quiz'
import ImportIcon from '@mui/icons-material/CloudUpload'
import FeedbackIcon from '@mui/icons-material/Feedback'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import BarChartIcon from '@mui/icons-material/BarChart'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import {
  Button,
  Chip,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {
  Outlet,
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import FoldersCollapseListItem from '../components/FoldersCollapseListItem'
import SearchInput from '../components/SearchInput'
import UserMenuButton from '../components/UserMenuButton'
import { pocketbase } from '../lib/config'
import FeedbackDialog from '../components/FeedbackDialog'
import ReviewDialog from '../components/ReviewDialog'
import DonateDialog from '../components/DonateDialog'

const drawerWidth: number = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  zIndex: 901,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: 900,
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

const user = pocketbase.authStore.model

const DashboardLayout = () => {
  const [open, setOpen] = useState(!!user)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  const [showReviewDialog, setShowReviewDialog] = useState(false)
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false)

  const [showDonateDialog, setShowDonateDialog] = useState(() => {
    if (!user) return false
    const lastSeen = localStorage.getItem('dd_seen')
    if (!lastSeen || user === null) return true
    const now = new Date()
    const lastSeenDate = new Date(lastSeen)
    const diff = now.getTime() - lastSeenDate.getTime()
    const diffHours = Math.ceil(diff / (1000 * 60 * 60)) // hours
    // return true if shown was not today and at least 8 hours ago
    return now.getDate() !== lastSeenDate.getDate() && diffHours > 8
  })
  const [donateButtonHovered, setDonateButtonHovered] = useState(false)

  const handleCloseDonateDialog = () => {
    setShowDonateDialog(false)
    const now = new Date()
    localStorage.setItem('dd_seen', now.toISOString())
  }

  const routerLocation = useLocation()
  const [searchParams] = useSearchParams()
  const query = searchParams.get('q') || ''

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <ReviewDialog
        open={showReviewDialog}
        onClose={() => setShowReviewDialog(false)}
      ></ReviewDialog>

      <FeedbackDialog
        open={showFeedbackDialog}
        onClose={() => setShowFeedbackDialog(false)}
      />

      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            <RouterLink to="/">Tentamera ✨</RouterLink>
          </Typography>

          {user && (
            <>
              {!showDonateDialog && (
                <IconButton
                  onClick={() => {
                    setShowDonateDialog(true)
                    setDonateButtonHovered(false)
                  }}
                  color={donateButtonHovered ? 'error' : 'inherit'}
                  sx={{ mr: 2 }}
                  onMouseEnter={() => setDonateButtonHovered(true)}
                  onMouseLeave={() => setDonateButtonHovered(false)}
                >
                  {donateButtonHovered ? (
                    <FavoriteIcon />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </IconButton>
              )}

              <Button
                variant="contained"
                color="secondary"
                startIcon={<FeedbackIcon />}
                sx={{ mr: 2 }}
                onClick={() => setShowFeedbackDialog(true)}
              >
                Feedback
              </Button>
              <SearchInput value={query} />
            </>
          )}

          <Box sx={{ ml: 2 }}>
            {user ? (
              <UserMenuButton user={user as any} />
            ) : (
              <Button component={RouterLink} to="/login" color="inherit">
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        {user && (
          <List component="nav">
            <ListItemButton
              component={RouterLink}
              to="/quiz"
              selected={routerLocation.pathname === '/quiz'}
            >
              <ListItemIcon>
                <QuizIcon />
              </ListItemIcon>
              <ListItemText primary="Quiz" />
            </ListItemButton>

            <ListItemButton
              component={RouterLink}
              to="/import"
              selected={routerLocation.pathname === '/import'}
            >
              <ListItemIcon>
                <ImportIcon />
              </ListItemIcon>
              <ListItemText primary="Import" />
            </ListItemButton>

            <ListItemButton
              component={RouterLink}
              to="/statistics"
              selected={routerLocation.pathname === '/statistics'}
            >
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="Statistics" />
            </ListItemButton>

            <ListItemButton
              onClick={() => setShowReviewDialog(true)}
              selected={showReviewDialog}
            >
              <ListItemIcon>
                <RestartAltIcon />
              </ListItemIcon>
              <ListItemText primary="Review" />
            </ListItemButton>

            <Divider sx={{ my: 1 }} />

            <FoldersCollapseListItem />

            <ListItemButton
              component={RouterLink}
              to="/tags"
              selected={routerLocation.pathname === '/tags'}
            >
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary="Tags" />
            </ListItemButton>
          </List>
        )}
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>

      <DonateDialog
        open={showDonateDialog}
        onClose={handleCloseDonateDialog}
      ></DonateDialog>
    </Box>
  )
}

export default DashboardLayout
