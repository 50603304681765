import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { pocketbase, POCKETBASE_URL } from '../lib/config'
import { Question } from '../types/global'
import useCollections from '../util/useCollections'
import DownloadIcon from '@mui/icons-material/Download'
import { FilterParams, getFilter } from '../util/useQuestions'

const fetchQuestions = async ({ queryKey }: any) => {
  const params = queryKey[1] as FilterParams & { expand: string }
  const filter = getFilter(params)
  return await pocketbase
    .collection('questions')
    .getFullList<Question>(undefined, {
      ...(filter && { filter }),
      expand: params.expand,
    })
}

type ExportDialogProps = {
  folder: {
    id: string
    name: string
  }
  open: boolean
  onClose: () => void
}

const ExportDialog: React.FC<ExportDialogProps> = ({
  folder,
  open,
  onClose,
}) => {
  const [collectionIds, setCollectionIds] = useState<string[]>([])
  const [includeUserMeta, setIncludeUserMeta] = useState(false)
  const [exportData, setExportData] = useState<string>()

  const { data: collections } = useCollections({
    folderId: folder.id,
  })

  const queryKey = [
    'questions',
    {
      collectionIds,
      expand: includeUserMeta
        ? 'alternatives,tags,question_user_meta(question)'
        : 'alternatives,tags',
    },
  ]

  const enabled = open && collectionIds && collectionIds.length > 0

  const {
    data: questions,
    isFetching: fetchQuestionsLoading,
    isSuccess,
  } = useQuery(queryKey, fetchQuestions, {
    enabled,
    keepPreviousData: true,
    staleTime: 1000 * 60,
  })

  const mapImages = (questionId: string, images?: string[]) => {
    return images?.map((filename: string) => ({
      url: `${POCKETBASE_URL}/api/files/questions/${questionId}/${filename}`,
    }))
  }

  useEffect(() => {
    if (collectionIds && collectionIds.length === 0) {
      setExportData('')
    }
  }, [collectionIds])

  useEffect(() => {
    if (questions && questions.length > 0) {
      const data = {
        version: '3.0',
        folders: [
          {
            name: folder.name,
            collections: collectionIds?.map((id) => {
              const collection = collections?.find((c) => c.id === id)

              return {
                name: collection?.name,
                questions: questions
                  .filter((question) => question.collection === collection?.id)
                  .map((question) => ({
                    type: 'multiple', // TODO: support other types
                    ...(question.name && { name: question.name }),
                    text: question.text,
                    ...(question.images && {
                      images: mapImages(question.id, question.images),
                    }),
                    alternatives: question.expand?.alternatives?.map(
                      (alternative: any) => ({
                        text: alternative.text,
                        // ...(alternative.images && {
                        //   images: mapImages(alternative.id, alternative.images),
                        // }),
                        ...(alternative.correct && { correct: true }),
                      })
                    ),
                    ...(question.explanation && {
                      explanation: question.explanation,
                    }),
                    ...(question.expand?.tags !== undefined && {
                      tags: question.expand?.tags?.map((tag) => tag.name),
                    }),
                    ...(includeUserMeta &&
                      question.expand?.[
                        'question_user_meta(question)'
                      ]?.[0] && {
                        ...(question.expand?.[
                          'question_user_meta(question)'
                        ]?.[0].flags && {
                          flags:
                            question.expand?.[
                              'question_user_meta(question)'
                            ]?.[0].flags,
                        }),
                        ...(question.expand?.[
                          'question_user_meta(question)'
                        ]?.[0].marked && {
                          marked:
                            question.expand?.[
                              'question_user_meta(question)'
                            ]?.[0].marked,
                        }),
                        ...(question.expand?.[
                          'question_user_meta(question)'
                        ]?.[0].note && {
                          note: question.expand?.[
                            'question_user_meta(question)'
                          ]?.[0].note,
                        }),
                      }),
                  })),
              }
            }),
          },
        ],
      }
      setExportData(JSON.stringify(data))
    }
  }, [questions, includeUserMeta])

  const downloadExportFile = () => {
    if (exportData) {
      const element = document.createElement('a')
      const file = new Blob([exportData], { type: 'text/plain' })
      element.href = URL.createObjectURL(file)
      element.download = `${folder.name}.json`
      document.body.appendChild(element) // Required for this to work in FireFox
      element.click()
      onClose()
    }
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Collections</InputLabel>
          <Select
            multiple
            autoWidth
            label="Collections"
            value={collectionIds}
            onChange={(e) => setCollectionIds(e.target.value as string[])}
            input={<OutlinedInput label="Collections" />}
            renderValue={(selected) =>
              selected
                .map(
                  (id) =>
                    collections?.find((collection) => collection.id === id)
                      ?.name
                )
                .join(', ')
            }
          >
            {collections?.length === 0 && (
              <MenuItem disabled>
                <em>No collections found</em>
              </MenuItem>
            )}
            {collections?.map((collection) => (
              <MenuItem key={collection.id} value={collection.id}>
                <Checkbox
                  checked={collectionIds?.indexOf(collection.id) > -1}
                />
                <ListItemText primary={collection.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              checked={includeUserMeta}
              onChange={(event, checked) => setIncludeUserMeta(checked)}
            />
          }
          label="Include user data (bookmarks, flags, notes)"
        />

        {fetchQuestionsLoading ? (
          <CircularProgress />
        ) : (
          isSuccess && (
            <>
              <TextField
                autoFocus
                fullWidth
                multiline
                rows={5}
                value={exportData}
              />

              <Typography variant="body2">
                Copy code or download file
              </Typography>
            </>
          )
        )}
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>
        {enabled && (
          <Button
            type="button"
            endIcon={<DownloadIcon />}
            onClick={downloadExportFile}
            disabled={fetchQuestionsLoading}
          >
            Download
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ExportDialog
