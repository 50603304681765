import { Container, Paper, Typography, Link, Alert } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import OrtracImportInstructions from '../components/OrtracImportInstructions'
import { pocketbase } from '../lib/config'

const HomePage = () => {
  const user = pocketbase.authStore.model

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {!user ? (
        <Paper sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            Welcome to Tentamera ✨
          </Typography>
          <Link component={RouterLink} to="/login">
            Log In
          </Link>{' '}
          or{' '}
          <Link component={RouterLink} to="/register">
            Register
          </Link>{' '}
          to get started.
        </Paper>
      ) : (
        <>
          <Alert color="info" className="mb-3">
            <strong>Viktigt meddelande:</strong> Läs mitt inlägg på{' '}
            <Link href="https://www.patreon.com/tentamera">
              patreon.com/tentamera
            </Link>
          </Alert>
          <Paper sx={{ p: 3 }}>
            <div className="prose">
              <Typography variant="h5" gutterBottom>
                Importera från Ortrac – Instruktioner
              </Typography>

              <OrtracImportInstructions />
            </div>
          </Paper>
        </>
      )}
    </Container>
  )
}

export default HomePage
