import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Stack,
  TextField,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  debounce,
  Alert,
} from '@mui/material'
import React, { useCallback, useState } from 'react'
import ImageLightbox from './ImageLightbox'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import DOMPurify from 'dompurify'
import { useFormik } from 'formik'
import { UpdateQuestionUserMetaData } from '../util/useQuestionUserMeta'

type QuizQuestionProps = {
  quizId: string
  index: number
  name?: string
  text?: string
  images?: string[]
  alternatives?: {
    id: string
    text: string
    correct: boolean
    explanation?: string
  }[]
  explanation?: string
  questionUserMeta?: {
    marked: boolean
    flags: string[]
    note: string
  }
  answer?: {
    id?: string
    alternativeIds?: string[]
    text?: string
  }
  showAnswer: boolean
  onAnswerChange: (data: { alternativeIds?: string[]; text?: string }) => void
  onQuestionUserMetaChange: (data: UpdateQuestionUserMetaData) => void
}

const QuizQuestion: React.FC<QuizQuestionProps> = (props) => {
  const formik = useFormik({
    initialValues: {
      alternativeIds: props.answer?.alternativeIds ?? [],
      text: props.answer?.text ?? '',
      marked: props.questionUserMeta?.marked ?? false,
    },
    enableReinitialize: true,
    onSubmit: () => {},
  })

  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false)
  const [lightboxImageIndex, setLightboxImageIndex] = useState<number>()

  const openLightbox = (index: number) => {
    setLightboxImageIndex(index)
    setLightboxOpen(true)
  }

  const questionText = DOMPurify.sanitize(props.text ?? '')

  const questionExplanation = props.explanation
    ? DOMPurify.sanitize(props.explanation)
    : undefined

  const shuffleArray = (array: any[], seed: string) => {
    // Create a hashing function to generate a deterministic sequence of random numbers
    function hash(str: string) {
      let h = 0
      for (let i = 0; i < str.length; i++) {
        h = (Math.imul(31, h) + str.charCodeAt(i)) | 0
      }
      return h
    }
    // Sort the array using a compare function that generates a random number
    // using the hash function as the sort key
    array.sort((a, b) => hash(a.id + seed) - hash(b.id + seed))
    return array
  }

  const getRandomlySortedAlternatives = useCallback(() => {
    const alternativesCopy = JSON.parse(JSON.stringify(props.alternatives))
    return shuffleArray(alternativesCopy, props.quizId)
  }, [props.alternatives])

  const handleCheckboxChange = (checked: boolean, alternativeId: string) => {
    const alternativeIds = checked
      ? [...formik.values.alternativeIds, alternativeId]
      : formik.values.alternativeIds.filter((id) => id !== alternativeId)
    formik.setFieldValue('alternativeIds', alternativeIds)
    props.onAnswerChange({ alternativeIds })
  }

  const debounceOnAnswerChange = debounce(
    (params: { text: string }) => props.onAnswerChange(params),
    2000
  )

  const debounceOnAnswerChangeCallback = useCallback(
    (params: { text: string }) => debounceOnAnswerChange(params),
    []
  )

  const handleTextInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const text = event.target.value
    formik.setFieldValue('text', text)
    debounceOnAnswerChangeCallback({ text })
  }

  const handleUpdateQuestionUserMeta = (data: { marked: boolean }) => {
    formik.setFieldValue('marked', data.marked)
    props.onQuestionUserMetaChange(data)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ maxWidth: '65ch' }}>
        {props.images !== undefined &&
          props.images.length > 0 &&
          lightboxOpen && (
            <ImageLightbox
              images={props.images}
              index={lightboxImageIndex}
              onClose={() => setLightboxOpen(false)}
            />
          )}

        <Typography fontWeight={700} gutterBottom>
          Question {props.index + 1} {props.name && `(${props.name})`}
        </Typography>

        <div className="prose">
          <span dangerouslySetInnerHTML={{ __html: questionText }}></span>
        </div>

        {props.images !== undefined && props.images.length > 0 && (
          <ImageList cols={6} sx={{ mt: 1.5, mb: 2 }}>
            {props.images.map((url, index) => (
              <ImageListItem
                key={url}
                onClick={() => openLightbox(index)}
                sx={{ cursor: 'pointer' }}
              >
                <img key={url} src={`${url}?thumb=100x100`} alt={url} />
              </ImageListItem>
            ))}
          </ImageList>
        )}

        {props.alternatives !== undefined && props.alternatives?.length > 0 ? (
          <FormGroup>
            {getRandomlySortedAlternatives().map((alternative) => {
              const alternativeText = DOMPurify.sanitize(alternative.text)
              const alternativeExplanation = alternative.explanation
                ? DOMPurify.sanitize(alternative.explanation)
                : undefined

              return (
                <Box key={alternative.id}>
                  <FormControlLabel
                    key={alternative.id}
                    disabled={props.showAnswer}
                    control={
                      props.showAnswer && alternative.correct ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '65px', // 56px + 9px
                            height: '42px',
                            paddingLeft: '9px',
                          }}
                        >
                          {formik.values.alternativeIds.includes(
                            alternative.id
                          ) ? (
                            <CheckCircleIcon color="success"></CheckCircleIcon>
                          ) : (
                            <CheckCircleOutlineIcon color="success"></CheckCircleOutlineIcon>
                          )}
                        </Box>
                      ) : (
                        <Checkbox
                          checked={formik.values.alternativeIds.includes(
                            alternative.id
                          )}
                          onChange={(event, checked) =>
                            handleCheckboxChange(checked, alternative.id)
                          }
                        />
                      )
                    }
                    label={
                      <Box>
                        <Typography
                          sx={{
                            fontWeight:
                              props.showAnswer &&
                              formik.values.alternativeIds.includes(
                                alternative.id
                              )
                                ? 700
                                : 400,
                          }}
                          dangerouslySetInnerHTML={{ __html: alternativeText }}
                        ></Typography>
                        {alternativeExplanation && props.showAnswer && (
                          <Alert
                            severity="info"
                            variant="outlined"
                            sx={{ mt: 1 }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: alternativeExplanation,
                              }}
                            ></span>
                          </Alert>
                        )}
                      </Box>
                    }
                    sx={{ my: 1 }}
                  />
                </Box>
              )
            })}
          </FormGroup>
        ) : (
          <Box sx={{ my: 3 }}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                multiline
                minRows={4}
                value={formik.values.text}
                onChange={handleTextInput}
                disabled={props.showAnswer}
              ></TextField>

              {!props.showAnswer && (
                <div>
                  <Button>Submit</Button>
                </div>
              )}
            </Stack>
          </Box>
        )}

        {questionExplanation && props.showAnswer && (
          <Alert severity="info" sx={{ mt: 2 }}>
            <span
              dangerouslySetInnerHTML={{ __html: questionExplanation }}
            ></span>
          </Alert>
        )}
      </Box>
      <Box sx={{ mt: -1, ml: 1 }}>
        <IconButton
          aria-label="bookmark"
          color={formik.values.marked ? 'warning' : 'default'}
          onClick={() =>
            handleUpdateQuestionUserMeta({ marked: !formik.values.marked })
          }
        >
          {formik.values.marked ? <StarIcon /> : <StarBorderIcon />}
        </IconButton>
      </Box>
    </Box>
  )
}

export default QuizQuestion
