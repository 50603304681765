import { Chip, Container, Stack, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import PaginatedQuestions from '../components/PaginatedQuestions'
import useTags from '../util/useTags'

const SearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const query = searchParams.get('q') || undefined
  const tagIds = searchParams.get('tags')?.split(',')

  const { data: tags } = useTags()

  const getTagById = (tagId: string) => {
    return tags?.find((tag) => tag.id === tagId)
  }

  const handleRemoveTag = (tagId: string) => {
    const tagIds = searchParams.get('tags')?.split(',')
    const newTagIds = tagIds?.filter((id) => id !== tagId)
    if (newTagIds && newTagIds?.length > 0) {
      searchParams.set('tags', newTagIds.join(','))
    } else {
      searchParams.delete('tags')
    }
    setSearchParams(searchParams)
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Search{query ? `: ${query}` : ''}
      </Typography>

      {tagIds && (
        <Stack direction="row" spacing={0.5} sx={{ mb: 1 }}>
          {tagIds?.map((tagId) => (
            <Chip
              key={tagId}
              label={getTagById(tagId)?.name ?? tagId}
              onDelete={(e) => handleRemoveTag(tagId)}
            />
          ))}
        </Stack>
      )}

      <PaginatedQuestions
        query={query}
        tagIds={tagIds}
        disableSearch
        showCollection
      />
    </Container>
  )
}
export default SearchPage
