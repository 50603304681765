import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import QuestionImagesInput from './QuestionImagesInput'
import TiptapEditor from './TiptapEditor'
import DeleteIcon from '@mui/icons-material/Delete'
import CommentIcon from '@mui/icons-material/Comment'
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled'
import cuid from 'cuid'
import AutocompleteTags from './AutocompleteTags'
import { pocketbase } from '../lib/config'
import { Alternative, Tag } from '../types/global'
import { LoadingButton } from '@mui/lab'
import { transformTags } from '../util/transformTags'
import { UpdateQuestionData } from '../util/useQuestions'
import { transformAlternatives } from '../util/transformAlternatives'

type QuestionDialogProps = {
  open: boolean
  id: string
  text?: string
  explanation?: string
  images?: string[]
  alternatives?: Alternative[]
  tags?: Tag[]
  tagOptions?: Tag[]
  submitLabel?: string
  isLoading?: boolean
  onSubmit?: (data: UpdateQuestionData) => void
  onClose: () => void
}

const QuestionDialog: React.FC<QuestionDialogProps> = (props) => {
  const formik = useFormik<{
    type: 'multiple' | 'single'
    text: string
    explanation: string
    images: string[]
    selectedImageFiles: File[]
    alternatives: {
      id: string
      text: string
      explanation: string
      hasExplanation: boolean
      correct: boolean
    }[]
    tags: (Tag | string)[]
    points: number
  }>({
    initialValues: {
      type: 'multiple',
      text: props.text ?? '',
      explanation: props?.explanation ?? '',
      images: props?.images ?? [],
      selectedImageFiles: [],
      alternatives:
        props.alternatives?.map((a) => ({
          ...a,
          hasExplanation: !!a.explanation,
        })) ?? [],
      tags: props.tags ?? [],
      points: 1,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {}
      if (!values.text || values.text === '<p></p>') {
        errors.text = 'Text is required'
      }
      if (values.alternatives.length === 1) {
        errors.alternatives = {}
        errors.alternatives[values.alternatives.length + 1] =
          'At least two alternatives are required'
      }
      if (
        values.alternatives.length >= 2 &&
        !values.alternatives.some((a) => a.correct)
      ) {
        errors.alternatives = {}
        errors.alternatives[values.alternatives.length + 1] =
          'At least one alternative must be correct'
      }
      values.alternatives.forEach((a, i) => {
        if (!a.text || a.text === '<p></p>') {
          errors.alternatives = {}
          errors.alternatives[i] = 'Text is required'
        }
      })
      return errors
    },
    onSubmit: async (values) => {
      const userId = pocketbase.authStore.model?.id
      if (!userId) return

      const tags = await transformTags(values.tags, userId)

      const images: Record<string | number, File | ''> = {}
      for (const image of props.images ?? []) {
        if (!values.images.includes(image)) {
          // deleted images
          images[image] = ''
        }
      }
      for (const file of values.selectedImageFiles) {
        images[file.name] = file
      }

      const alternatives = await transformAlternatives(
        values.alternatives,
        props.alternatives
      )

      props.onSubmit?.({
        ...values,
        images,
        alternatives,
        tags,
      })
    },
  })

  const handleClose = () => {
    props.onClose()
    formik.resetForm()
  }

  const handleDeleteAlternative = (index: number) => {
    formik.setFieldValue(
      'alternatives',
      formik.values.alternatives.filter((a, i) => i !== index)
    )
  }

  const handleAddAlternative = () => {
    formik.setFieldValue('alternatives', [
      ...formik.values.alternatives,
      {
        id: cuid(),
        text: '',
        explanation: '',
        hasExplanation: false,
        correct: false,
      },
    ])
  }

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers sx={{ maxHeight: '80vh' }}>
          <Box sx={{ mb: 2 }}>
            <AutocompleteTags
              size="small"
              options={props.tagOptions}
              tags={formik.values.tags}
              onChange={(value) => formik.setFieldValue('tags', value)}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <TiptapEditor
              defaultValue={props.text}
              onChange={(value) => formik.setFieldValue('text', value)}
            />

            {formik.errors.text && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {formik.errors.text}
              </Typography>
            )}
          </Box>

          <Box sx={{ mb: 2 }}>
            <QuestionImagesInput
              questionId={props.id}
              images={formik.values.images}
              selectedImageFiles={formik.values.selectedImageFiles}
              onChange={(images) => formik.setFieldValue('images', images)}
              onSelectedChange={(files) =>
                formik.setFieldValue('selectedImageFiles', files)
              }
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            {formik.values.alternatives?.map((alternative, index) => (
              <Box
                key={alternative.id}
                sx={{ display: 'flex', mx: -1, mt: 1, mb: 1 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={alternative.correct}
                    onChange={(e, checked) =>
                      formik.setFieldValue(
                        `alternatives.${index}.correct`,
                        checked
                      )
                    }
                  />
                </Box>

                <Box sx={{ flexGrow: 1 }}>
                  <TiptapEditor
                    defaultValue={props.alternatives?.[index]?.text}
                    onChange={(value) => {
                      formik.setFieldValue(`alternatives.${index}.text`, value)
                    }}
                  />

                  {formik.errors.alternatives?.[index] && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                      {formik.errors.alternatives?.[index].toString()}
                    </Typography>
                  )}

                  {alternative.hasExplanation && (
                    <TextField
                      fullWidth
                      size="small"
                      label="Explanation"
                      name={`alternatives.${index}.explanation`}
                      value={alternative.explanation}
                      onChange={formik.handleChange}
                      sx={{ mt: 1, mb: 2 }}
                    />
                  )}
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={() =>
                      formik.setFieldValue(
                        `alternatives.${index}.hasExplanation`,
                        !alternative.hasExplanation
                      )
                    }
                  >
                    {alternative.hasExplanation ? (
                      <Tooltip title="Remove explanation">
                        <CommentsDisabledIcon color="primary" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add explanation">
                        <CommentIcon />
                      </Tooltip>
                    )}
                  </IconButton>

                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteAlternative(index)}
                    sx={(theme) => ({
                      ':hover': {
                        color: theme.palette.error.main,
                      },
                    })}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}

            {formik.errors.alternatives?.[
              formik.values.alternatives.length + 1
            ] && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {formik.errors.alternatives?.[
                  formik.values.alternatives.length + 1
                ].toString()}
              </Typography>
            )}

            <Button onClick={handleAddAlternative} sx={{ mt: 1 }}>
              Add alternative
            </Button>
          </Box>

          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              multiline
              label="Explanation"
              name="explanation"
              value={formik.values.explanation}
              onChange={formik.handleChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={props.isLoading}>
            {props.submitLabel ?? 'Submit'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default QuestionDialog
