import { pocketbase } from '../lib/config'
import { ExportData } from '../types/export'
import { Collection, Folder, QuestionUserMeta, Tag } from '../types/global'
import { createAlternative } from '../util/useAlternatives'
import JSZip, { JSZipObject } from 'jszip'
import { ExportData_2_0 } from '../types/export-gamma'
import { createTag } from './useTags'
import { canvasHtmlExportParser } from './parsers'

const createQuestion = async (data: FormData) => {
  return await pocketbase.collection('questions').create(data)
}

interface CreateQuestionUserMetaData {
  user: string
  question: string
  marked?: boolean
  flags?: string[]
  note?: string
}

const createQuestionUserMeta = async (data: CreateQuestionUserMetaData) => {
  return await pocketbase
    .collection('question_user_meta')
    .create<QuestionUserMeta>(data)
}

const createCollection = async (data: { name: string; folder: string }) => {
  return await pocketbase.collection('collections').create<Collection>(data)
}

const createFolder = async (data: { name: string; user: string }) => {
  return await pocketbase.collection('folders').create<Folder>(data)
}

const fetchFile = async (url: string) => {
  const res = await fetch(url)
  const blob = await res.blob()
  return blob
}

type ImportExportDataProps = {
  userId: string
  data: ExportData
  imageFiles?: JSZipObject[]
  totalQuestions: number
  tagOptions?: Tag[]
  selectedFolders?: { id?: string; collections: { id?: string }[] }[]
  onProgress?: (params: { index: number; progress: number }) => void
  onError?: (params: { index: number; error: Error; message: string }) => void
}

export const importExportData = async (props: ImportExportDataProps) => {
  const tags = [...(props.tagOptions ?? [])]
  let questionIndex = 0
  const folderIds = []
  const collectionIds = []
  for (let i = 0; i < props.data.folders.length; i++) {
    const folderData = props.data.folders[i]

    let folderId = props.selectedFolders?.[i]?.id
    if (folderId === undefined) {
      const folder = await createFolder({
        user: props.userId,
        name: folderData.name ?? `Folder ${i + 1}`,
      })
      folderId = folder.id
    }
    folderIds.push(folderId)

    if (folderData.collections && folderData.collections.length > 0) {
      for (let j = 0; j < folderData.collections.length; j++) {
        const collectionData = folderData.collections[j]

        let collectionId = props.selectedFolders?.[i]?.collections?.[j]?.id
        if (collectionId === undefined) {
          const collection = await createCollection({
            name: collectionData.name ?? `Collection ${j + 1}`,
            folder: folderId,
          })
          collectionId = collection.id
        }
        collectionIds.push(collectionId)

        if (collectionData.questions && collectionData.questions.length > 0) {
          let previousQuestionId: string | undefined = undefined
          for (let k = 0; k < collectionData.questions.length; k++) {
            const questionData = collectionData.questions[k]

            const formData = new FormData()

            formData.append('collection', collectionId)
            formData.append('index', k.toString())
            formData.append('type', questionData.type ?? 'multiple')
            formData.append('name', questionData.name ?? '')
            formData.append('text', questionData.text ?? '')
            formData.append('explanation', questionData.explanation ?? '')
            formData.append('points', questionData.points?.toString() ?? '1')
            formData.append('previous', previousQuestionId ?? '')

            if (questionData.images && questionData.images.length > 0) {
              for (let l = 0; l < questionData.images?.length; l++) {
                let url = questionData.images?.[l].url

                try {
                  if (props.imageFiles) {
                    const imageFile = props.imageFiles.find(
                      (file) => file.name === url
                    )
                    if (imageFile) {
                      const image = await imageFile.async('blob')
                      formData.append('images', image)
                      continue
                    }
                  }
                  const blob = await fetchFile(url)
                  formData.append('images', blob)
                } catch (error: any) {
                  console.log(error)
                  props.onError?.({
                    index: questionIndex,
                    error,
                    message: `Failed to fetch image (${url}, Question ${
                      k + 1
                    }, Collection ${collectionId})`,
                  })
                }
              }
            }

            if (questionData.tags && questionData.tags.length > 0) {
              for (let l = 0; l < questionData.tags?.length; l++) {
                const tagName = questionData.tags?.[l]
                const tag = tags.find((tag) => tag.name === tagName)
                if (tag) {
                  formData.append('tags', tag.id)
                } else {
                  try {
                    const createdTag = await createTag({
                      user: props.userId,
                      name: tagName,
                    })
                    tags.push(createdTag)
                    formData.append('tags', createdTag.id)
                  } catch (error: any) {
                    console.log(error)
                    props.onError?.({
                      index: questionIndex,
                      error,
                      message: `Failed to create tag (${tagName}, Question ${
                        k + 1
                      }, Collection ${collectionId})`,
                    })
                  }
                }
              }
            }

            if (
              questionData.alternatives &&
              questionData.alternatives.length > 0
            ) {
              for (let l = 0; l < questionData.alternatives?.length; l++) {
                const alternativeData = questionData.alternatives[l]
                try {
                  const alternative = await createAlternative({
                    ...questionData.alternatives[l],
                    correct: alternativeData.correct ?? false,
                  })
                  formData.append('alternatives', alternative.id)
                } catch (error: any) {
                  console.log(error)
                  props.onError?.({
                    index: questionIndex,
                    error,
                    message: `Failed to create alternative (${alternativeData.text.slice(
                      0,
                      16
                    )}, Question ${k + 1}, Collection ${collectionId})`,
                  })
                }
              }
            }

            try {
              const question = await createQuestion(formData)

              if (
                questionData.marked ||
                questionData.flags ||
                questionData.note
              ) {
                await createQuestionUserMeta({
                  user: props.userId,
                  question: question.id,
                  marked: questionData.marked,
                  flags: questionData.flags,
                  note: questionData.note,
                })
              }

              previousQuestionId = question.id
              questionIndex += 1
              props.onProgress?.({
                index: questionIndex,
                progress: (questionIndex / props.totalQuestions) * 100,
              })
            } catch (error: any) {
              console.log(error)
              props.onError?.({
                index: questionIndex,
                error,
                message: `Failed to create question (Question ${
                  k + 1
                }, Collection ${collectionId})`,
              })
            }
          }
        }
      }
    }
  }

  return {
    folderIds,
    collectionIds,
  }
}

const parseZipFile = async (file: File) => {
  try {
    const zip = await JSZip.loadAsync(file)

    const exportJsonFile = zip.file('export.json')

    const exportJsonFileString = await exportJsonFile?.async('string')

    let exportData: ExportData_2_0
    try {
      exportData = JSON.parse(exportJsonFileString ?? '')
    } catch (error) {
      return null
    }

    const imageFiles = zip.file(/img\/.*/)

    const categories = exportData.folders.flatMap((folder) => folder.categories)

    return {
      data: exportData.folders.map((folder) => ({
        name: folder.name,
        collections: folder.collections.map((collection) => ({
          name: collection.name,
          questions: collection.questions.map((question) => ({
            type: 'multiple' as 'multiple',
            points: 1,
            text: question.text,
            images: question.imageFilenames.map((filename) => ({
              url: `img/${filename}`,
            })),
            alternatives: question.alternatives.map((alternative) => ({
              text: alternative.text,
              correct: alternative.correct ?? false,
              explanation: alternative.comment ?? undefined,
            })),
            tags: categories
              .filter((category) => question.categoryIds.includes(category.id))
              .map((category) => category.name),
            marked: question.bookmarked,
            explanation: question.comment ?? undefined,
          })),
        })),
      })),
      imageFiles,
    }
  } catch (error) {
    return null
  }
}

const parseHtmlFile = async (file: File) => {
  const text = await file.text()
  const parser = new DOMParser()
  const doc = parser.parseFromString(text, 'text/html')
  return canvasHtmlExportParser(doc)
}

export const parseExportFile = async (
  file: File
): Promise<{
  data: ExportData
  imageFiles?: JSZip.JSZipObject[]
} | null> => {
  if (file.type === 'application/json') {
    const text = await file.text()
    const data = JSON.parse(text)
    return { data }
  }
  if (file.type === 'text/html') {
    const folders = await parseHtmlFile(file)
    if (folders === null) {
      return null
    }
    return {
      data: {
        version: '3.0',
        folders,
      },
    }
  }

  const data = await parseZipFile(file)
  if (data?.data === undefined) {
    return null
  }
  return {
    data: {
      version: '3.0',
      folders: data?.data,
    },
    imageFiles: data?.imageFiles,
  }
}
