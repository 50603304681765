import {
  Alert,
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Link as RouterLink } from 'react-router-dom'
import QuizQuestion from '../components/QuizQuestion'
import useQuizSession, { updateQuizSession } from '../util/useQuizSession'
import { POCKETBASE_URL } from '../lib/config'
import FeedbackDialog from '../components/FeedbackDialog'
import FeedbackIcon from '@mui/icons-material/Feedback'
import StarIcon from '@mui/icons-material/Star'

const QuizPage = () => {
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (showFeedbackDialog) return
      if (e.code === 'ArrowRight') {
        next()
      } else if (e.code === 'ArrowLeft') {
        previous()
      } else if (e.code === 'Space') {
        e.preventDefault()
        e.stopPropagation()
        window.scrollTo(0, document.body.scrollHeight)
        setShowAnswer((showAnswer) => !showAnswer)
      }
    }
    document.addEventListener('keydown', handleKeydown)
    return () => {
      // remove event listener on component unmount
      document.removeEventListener('keydown', handleKeydown)
    }
  })

  const { id: quizSessionId } = useParams<{ id: string }>()

  const [index, setIndex] = useState(0)

  const {
    isLoading,
    isError,
    error,
    isSuccess,
    quizSession,
    quiz,
    questions,
    upsertQuestionUserMeta,
    answers,
    upsertAnswer,
  } = useQuizSession({ id: quizSessionId as string })

  const [showAnswer, setShowAnswer] = useState(false)
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false)

  const currentQuestion =
    questions !== undefined ? questions.items[index] : undefined
  const alternatives = currentQuestion?.expand?.alternatives ?? []
  const currentQuestionAnswer =
    currentQuestion?.id !== undefined ? answers[currentQuestion.id] : undefined

  const handleAnswerChange = (params: {
    alternativeIds?: string[]
    text?: string
  }) => {
    if (currentQuestion === undefined) {
      return
    }
    upsertAnswer({
      id: currentQuestionAnswer?.id,
      questionId: currentQuestion.id,
      alternativeIds: params.alternativeIds,
      text: params.text,
    })
  }

  const handleUpdateQuestionUserMeta = (params: { marked?: boolean }) => {
    if (currentQuestion === undefined) {
      return
    }
    upsertQuestionUserMeta({
      id: currentQuestion?.expand?.['question_user_meta(question)']?.[0].id,
      data: {
        questionId: currentQuestion?.id,
        marked: params.marked,
      },
    })
  }

  const navigateToQuestionIndex = (index: number) => {
    setShowAnswer(false)
    setIndex(index)
  }

  const next = () => {
    setShowAnswer(false)
    if (questions !== undefined && index < questions.items.length - 1) {
      setIndex(index + 1)
    } else {
      setIndex(0)
    }
    window.scrollTo(0, 0)
  }

  const previous = () => {
    setShowAnswer(false)
    if (index > 0) {
      setIndex(index - 1)
    } else if (questions !== undefined) {
      setIndex(questions.items.length - 1)
    }
    window.scrollTo(0, 0)
  }

  const navigate = useNavigate()

  const handleQuizFinished = async () => {
    if (!quizSession?.finished) {
      await updateQuizSession(quizSessionId as string, {
        finished: true,
        finished_at: new Date().toISOString(),
      })
    }
    navigate(`/quiz/${quizSessionId}/result`)
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <FeedbackDialog
        open={showFeedbackDialog}
        onClose={() => setShowFeedbackDialog(false)}
      />

      <AppBar sx={{ zIndex: 900 }}>
        <Toolbar>
          <Typography sx={{ flexGrow: 1 }}>{quiz?.name || 'Quiz'}</Typography>

          <Button
            variant="contained"
            color="secondary"
            startIcon={<FeedbackIcon />}
            sx={{ mr: 2 }}
            onClick={() => setShowFeedbackDialog(true)}
          >
            Feedback
          </Button>

          <Button component={RouterLink} to="/quiz" color="inherit">
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <Toolbar />

      {isLoading && <CircularProgress />}

      {isError && (
        <Alert severity="error">
          Error fetching quiz: {(error as any).message}
        </Alert>
      )}

      {isSuccess && currentQuestion !== undefined && quiz?.id !== undefined && (
        <Grid container spacing={4} sx={{ mb: 12 }}>
          <Grid item xs={12} md={8}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box>
                <QuizQuestion
                  quizId={quiz.id}
                  index={index}
                  name={currentQuestion.name}
                  text={currentQuestion.text}
                  images={currentQuestion.images.map(
                    (filename: string) =>
                      `${POCKETBASE_URL}/api/files/questions/${currentQuestion.id}/${filename}`
                  )}
                  alternatives={alternatives}
                  explanation={currentQuestion.explanation}
                  questionUserMeta={
                    currentQuestion?.expand?.[
                      'question_user_meta(question)'
                    ]?.[0]
                  }
                  answer={currentQuestionAnswer}
                  showAnswer={showAnswer}
                  onAnswerChange={handleAnswerChange}
                  onQuestionUserMetaChange={handleUpdateQuestionUserMeta}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs>
            <Grid container spacing={0.75}>
              {questions?.items.map((question: any, i: number) => {
                const questionAnswer = answers[question.id]
                const hasAnswered =
                  (questionAnswer?.alternativeIds &&
                    questionAnswer?.alternativeIds.length > 0) ||
                  !!questionAnswer?.text
                const isMarked =
                  question?.expand?.['question_user_meta(question)']?.[0]
                    ?.marked

                const variant = hasAnswered
                  ? 'contained'
                  : i === index
                  ? 'outlined'
                  : 'text'

                return (
                  <Grid key={question.id} item>
                    <Button
                      variant={variant}
                      color={i === index ? 'secondary' : undefined}
                      disableElevation
                      size="small"
                      onClick={() => navigateToQuestionIndex(i)}
                    >
                      {isMarked && (
                        <StarIcon
                          fontSize="small"
                          color="warning"
                          sx={{ position: 'absolute', top: 0, right: 0 }}
                        ></StarIcon>
                      )}
                      {i + 1}
                    </Button>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      )}

      <AppBar
        position="fixed"
        sx={{ top: 'auto', bottom: 0, zIndex: 900 }}
        color="inherit"
      >
        <Toolbar>
          <FormGroup>
            <Tooltip
              title={
                quiz?.disable_show_answer
                  ? 'Show answer disabled'
                  : 'Show answer'
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={showAnswer}
                    onChange={(event, checked) => setShowAnswer(checked)}
                    disabled={quiz?.disable_show_answer}
                  />
                }
                label={showAnswer ? <VisibilityOffIcon /> : <VisibilityIcon />}
                labelPlacement="start"
              />
            </Tooltip>
          </FormGroup>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Button onClick={previous}>Previous</Button>
          <Button onClick={next}>Next question</Button>
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }}></Divider>
          <Button onClick={handleQuizFinished}>Finish</Button>
        </Toolbar>
      </AppBar>
    </Container>
  )
}

export default QuizPage
