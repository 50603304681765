import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { pocketbase } from '../lib/config'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { Alert, CircularProgress, Stack } from '@mui/material'

const handleLogin = async (email: string, password: string) => {
  return await pocketbase.collection('users').authWithPassword(email, password)
}

const handleLoginAdmin = async (email: string, password: string) => {
  return await pocketbase.admins.authWithPassword(email, password)
}

export default function AuthPage() {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])

  const [searchParams] = useSearchParams()
  const admin = Boolean(searchParams.get('admin'))
  const redirected = Boolean(searchParams.get('redirected'))
  const redirectTo = searchParams.get('redirectTo') || '/'
  const registered = Boolean(searchParams.get('registered'))

  const handleError = (error: any) => {
    if (error.message === 'Failed to authenticate.') {
      setErrors([
        ...errors.filter((e) => e !== 'Invalid email or password.'),
        'Invalid email or password.',
      ])
    } else {
      setErrors([...errors.filter((e) => e !== error.message), error.message])
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    setLoading(true)
    try {
      if (admin) {
        await handleLoginAdmin(
          data.get('email') as string,
          data.get('password') as string
        )
      } else {
        await handleLogin(
          data.get('email') as string,
          data.get('password') as string
        )
      }

      window.location.href = redirectTo
    } catch (error: any) {
      handleError(error)
    }
    setLoading(false)
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>

        {redirected ? (
          <Alert severity="info" sx={{ mt: 2 }}>
            You need to log in to access this page.
          </Alert>
        ) : !registered ? (
          <Alert severity="info" sx={{ mt: 2 }}>
            Du behöver skapa ett nytt konto om du inte redan gjort det på den
            här versionen av Tentamera.
          </Alert>
        ) : (
          <Alert severity="success" sx={{ mt: 2 }}>
            Please log in to continue.
          </Alert>
        )}

        {!loading && errors && errors.length > 0 && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <Stack>
              {errors.map((error, index) => (
                <Typography key={index}>{error}</Typography>
              ))}
            </Stack>
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Log in'
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} variant="body2" to="/password-reset">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} variant="body2" to="/register">
                Create account
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}
