import { Alternative, Tag } from '../types/global'

type QuestionData = {
  id: string
  text?: string
  images?: string[]
  alternatives?: Alternative[]
  explanation?: string
  tags?: Tag[]
}

export function formatAnkiCSV(data: QuestionData[]) {
  // Create a CSV string with headers
  // https://docs.ankiweb.net/importing/text-files.html
  let csv =
    '#separator:Semicolon\n#html:true\n#columns:ID;Text;Images;Alternatives;Answer;Explanation;Tags\n#notetype:Tentamera\n#tags column:7\n'

  for (let i = 0; i < data.length; i++) {
    const questionData = data[i]
    csv += formatLine(questionData)
  }

  return csv
}

function formatLine(data: QuestionData) {
  return `${data.id};${formatText(data.text)};${formatImagesColumn(
    data.images
  )};${formatAlternativesColumn(data.alternatives)};${formatAnswerColumn(
    data.alternatives
  )};${formatText(data.explanation)};${formatTagsColumn(data.tags)}\n`
}

function formatText(text?: string) {
  return text?.replace(/[\r\n;]+/g, ' ')
}

function formatImagesColumn(filenames?: string[]) {
  if (!filenames || filenames.length === 0) {
    return ''
  }
  return filenames.map((filename) => `<img src="${filename}">`).join(' ')
}

function formatAlternativesColumn(alternatives?: Alternative[]) {
  if (!alternatives || alternatives.length === 0) {
    return ''
  }
  return `<ol type="A">${alternatives
    .map((alternative) => `<li>${formatText(alternative.text)}</li>`)
    .join(' ')}</ol>`
}

function formatAnswerColumn(alternatives?: Alternative[]) {
  if (!alternatives || alternatives.length === 0) {
    return ''
  }

  const correctAlternatives = alternatives.filter(
    (alternative) => alternative.correct
  )

  return `<ol type="A">${correctAlternatives
    .map(
      (alternative) =>
        `<li value="${alternatives.indexOf(alternative) + 1}">${formatText(
          alternative.text
        )}</li>`
    )
    .join(' ')}</ol>`
}

function formatTagsColumn(tags?: Tag[]) {
  if (!tags || tags.length === 0) {
    return ''
  }
  return tags.map((tag) => formatText(tag.name)).join(' ')
}
