import {
  Breadcrumbs,
  Typography,
  Link,
  Alert,
  CircularProgress,
  Box,
  Button,
  Toolbar,
  AppBar,
  Container,
  IconButton,
} from '@mui/material'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import QuizIcon from '@mui/icons-material/Quiz'
import PrintIcon from '@mui/icons-material/Print'
import DownloadIcon from '@mui/icons-material/FileDownload'
import QuizDialog from '../components/QuizDialog'
import React from 'react'
import useCollection from '../util/useCollection'
import { pocketbase } from '../lib/config'
import CollectionDialog from '../components/CollectionDialog'
import useTags from '../util/useTags'
import PaginatedQuestions from '../components/PaginatedQuestions'
import { downloadQuestions } from '../util/useDownload'

const updateCollection = async (
  id: string,
  data: { name: string; folder: string }
) => {
  return await pocketbase.collection('collections').update(id, data)
}

const deleteCollection = async (id: string) => {
  return await pocketbase.collection('collections').delete(id)
}

const CollectionPage = () => {
  const { id: collectionId } = useParams<{ id: string }>()

  const {
    isLoading,
    data: collection,
    isError,
    error,
    isSuccess,
    invalidateQueries,
  } = useCollection(collectionId as string)

  const { data: tags } = useTags()

  const folder = collection?.expand?.folder

  const [collectionDialogOpen, setCollectionDialogOpen] = React.useState(false)
  const [quizDialogOpen, setQuizDialogOpen] = React.useState(false)
  const [isLoadingDeleteCollection, setIsLoadingDeleteCollection] =
    React.useState(false)
  const [isLoadingDownloadQuestions, setIsLoadingDownloadQuestions] =
    React.useState(false)

  const handleUpdateCollection = async (data: any) => {
    await updateCollection(collectionId as string, data)
    invalidateQueries()
    setCollectionDialogOpen(false)
  }

  const navigate = useNavigate()

  const handleDeleteCollection = async () => {
    if (!confirm('Are you sure you want to delete this collection?')) {
      return
    }
    setIsLoadingDeleteCollection(true)
    await deleteCollection(collectionId as string)
    navigate(`/folder/${folder?.id}`)
  }

  const handleDownloadQuestions = async () => {
    if (!collection) return
    setIsLoadingDownloadQuestions(true)
    await downloadQuestions({ collectionId: collection.id }, collection.name)
    setIsLoadingDownloadQuestions(false)
  }

  return (
    <>
      {isSuccess && (
        <>
          <CollectionDialog
            collection={collection as any}
            selectFolder
            open={collectionDialogOpen}
            onClose={() => setCollectionDialogOpen(false)}
            onSubmit={handleUpdateCollection}
          />

          <QuizDialog
            folderId={collection.folder}
            collections={[collection]}
            tags={tags}
            selectedCollectionIds={[collectionId as string]}
            open={quizDialogOpen}
            onClose={() => setQuizDialogOpen(false)}
          />
        </>
      )}

      <AppBar position="static" color="inherit">
        <Toolbar>
          {isSuccess && (
            <>
              <Box sx={{ flexGrow: 1 }}>
                {isSuccess && (
                  <Breadcrumbs aria-label="breadcrumb" sx={{ py: 1 }}>
                    {folder && (
                      <Link
                        component={RouterLink}
                        to={`/folder/${folder.id}`}
                        underline="hover"
                        color="inherit"
                      >
                        {folder.name}
                      </Link>
                    )}
                    <Typography color="text.primary" fontWeight={500}>
                      {collection.name}
                    </Typography>
                  </Breadcrumbs>
                )}
              </Box>

              <Box sx={{ mr: 1 }}>
                <IconButton
                  color="inherit"
                  onClick={() => setCollectionDialogOpen(true)}
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  color="inherit"
                  onClick={handleDeleteCollection}
                  sx={(theme) => ({
                    ':hover': {
                      color: theme.palette.error.main,
                    },
                  })}
                >
                  {isLoadingDeleteCollection ? (
                    <CircularProgress size={24} />
                  ) : (
                    <DeleteIcon />
                  )}
                </IconButton>

                <IconButton color="inherit" onClick={handleDownloadQuestions}>
                  {isLoadingDownloadQuestions ? (
                    <CircularProgress size={24} />
                  ) : (
                    <DownloadIcon />
                  )}
                </IconButton>

                <IconButton component={RouterLink} to="print" color="inherit">
                  <PrintIcon />
                </IconButton>
              </Box>

              <Button
                startIcon={<QuizIcon />}
                onClick={() => setQuizDialogOpen(true)}
              >
                Quiz
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {isLoading && <CircularProgress />}

        {isError && (
          <Alert severity="error">
            Error fetching collection: {(error as any).message}
          </Alert>
        )}

        {isSuccess && (
          <PaginatedQuestions collectionIds={[collectionId as string]} />
        )}
      </Container>
    </>
  )
}

export default CollectionPage
