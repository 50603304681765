import { Alert, Stack } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Question } from '../types/global'
import { UpdateQuestionData } from '../util/useQuestions'
import { UpdateQuestionUserMetaData } from '../util/useQuestionUserMeta'
import useTags from '../util/useTags'
import QuestionDialog from './QuestionDialog'
import QuestionItem from './QuestionItem'

type QuestionItemsProps = {
  questions: Question[]
  answers?: {
    [questionId: string]: {
      alternativeIds?: string[]
      text?: string
    }
  }
  showAnswer?: boolean
  displayTitles?: boolean
  highlightQuery?: string
  updateQuestionIsLoading?: boolean
  updateQuestionIsSuccess?: boolean
  onEditQuestion?: (questionId: string) => void
  onUpdateQuestion?: (questionId: string, data: UpdateQuestionData) => void
  onDeleteQuestion?: (questionId: string) => void
  onQuestionUserMetaChange?: (
    id: string | undefined,
    data: { questionId: string } & UpdateQuestionUserMetaData
  ) => void
}

const QuestionItems: React.FC<QuestionItemsProps> = (props) => {
  const [editQuestion, setEditQuestion] = useState<Question | null>(null)
  const [questionDialogOpen, setQuestionDialogOpen] = useState(false)

  const { data: tagOptions, refetch: refetchTags } = useTags()

  const handleEditQuestion = (questionId: string) => {
    const question = props.questions.find((q) => q.id === questionId)
    if (question) {
      setEditQuestion(question)
      setQuestionDialogOpen(true)
    }
  }

  const handleUpdateQuestion = (
    questionId: string,
    data: UpdateQuestionData
  ) => {
    if (
      data.tags !== undefined &&
      data.tags.map((tag) => tag.id).join(',') !==
        editQuestion?.expand?.tags?.map((tag) => tag.id).join(',')
    ) {
      // Tags have changed
      refetchTags()
    }
    props.onUpdateQuestion?.(questionId, data)
  }

  useEffect(() => {
    if (props.updateQuestionIsSuccess) {
      // Close dialog when update is successful
      setQuestionDialogOpen(false)
    }
  }, [props.updateQuestionIsSuccess])

  const questionTitleById = useMemo(
    () =>
      props.questions?.reduce((acc, question, index) => {
        acc[question.id] = `Question ${index + 1}`
        return acc
      }, {} as Record<string, string>),
    [props.questions]
  )

  if (props.questions.length === 0) {
    return <Alert severity="info">No questions found</Alert>
  }

  return (
    <>
      {editQuestion && (
        <QuestionDialog
          open={questionDialogOpen}
          id={editQuestion.id}
          text={editQuestion.text}
          explanation={editQuestion.explanation}
          images={editQuestion.images}
          alternatives={editQuestion.expand?.alternatives}
          tags={editQuestion.expand?.tags}
          tagOptions={tagOptions}
          submitLabel="Update"
          isLoading={props.updateQuestionIsLoading}
          onSubmit={(data) => handleUpdateQuestion(editQuestion.id, data)}
          onClose={() => setQuestionDialogOpen(false)}
        />
      )}

      <Stack spacing={2}>
        {props.questions.map((question) => (
          <QuestionItem
            key={question.id}
            title={
              props.displayTitles ? questionTitleById?.[question.id] : undefined
            }
            question={question}
            alternatives={question.expand?.alternatives}
            collection={question.expand?.collection}
            tags={question.expand?.tags}
            tagOptions={tagOptions}
            highlightQuery={props.highlightQuery}
            answer={props.answers?.[question.id]}
            showAnswer={props.showAnswer}
            onEditQuestion={() => handleEditQuestion(question.id)}
            onUpdateQuestion={(data) => handleUpdateQuestion(question.id, data)}
            onDeleteQuestion={() => props.onDeleteQuestion?.(question.id)}
            onQuestionUserMetaChange={(data) =>
              props.onQuestionUserMetaChange?.(
                question.expand?.['question_user_meta(question)']?.[0]?.id,
                {
                  ...data,
                  questionId: question.id,
                }
              )
            }
          />
        ))}
      </Stack>
    </>
  )
}

export default QuestionItems
