import { Autocomplete, TextField } from '@mui/material'

type AutocompleteTagsProps = {
  options?: {
    id: string
    name: string
  }[]
  tags?: (
    | {
        id: string
        name: string
      }
    | string
  )[]
  autoFocus?: boolean
  placeholder?: string
  variant?: 'outlined' | 'standard' | 'filled'
  size?: 'small' | 'medium'
  onChange?: (
    value: (
      | string
      | {
          id: string
          name: string
        }
    )[]
  ) => void
  onBlur?: () => void
}

const AutocompleteTags: React.FC<AutocompleteTagsProps> = ({
  options = [],
  tags,
  autoFocus,
  placeholder,
  variant,
  size,
  onChange,
  onBlur,
}) => {
  return (
    <Autocomplete
      freeSolo
      multiple
      autoHighlight
      filterSelectedOptions
      openOnFocus
      size={size}
      placeholder={placeholder}
      options={options}
      getOptionLabel={(option) =>
        typeof option === 'object' ? option.name : option
      }
      isOptionEqualToValue={(option, value) =>
        (typeof option === 'object' ? option.id : option) ===
        (typeof value === 'object' ? value.id : value)
      }
      value={tags}
      onChange={(e, value) => onChange?.(value)}
      onBlur={onBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          label={placeholder}
          variant={variant}
          size={size}
        />
      )}
    />
  )
}

export default AutocompleteTags
