import {
  Container,
  Alert,
  CircularProgress,
  Box,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import useQuestions from '../util/useQuestions'
import QuestionCard from '../components/QuestionCard'
import { POCKETBASE_URL } from '../lib/config'
import { useState } from 'react'

const CollectionPrintPage = () => {
  const { id: collectionId } = useParams<{ id: string }>()
  const [showAnswer, setShowAnswer] = useState(false)

  const { isLoading, isError, error, data } = useQuestions({
    collectionIds: collectionId ? [collectionId] : undefined,
    perPage: 200,
    expand: 'alternatives',
  })

  if (isLoading) {
    return <CircularProgress />
  }

  if (isError) {
    return (
      <Alert severity="error">
        Error fetching questions: {(error as any).message}
      </Alert>
    )
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'right' }}>
        <FormControlLabel
          control={
            <Switch
              checked={showAnswer}
              onChange={(event, checked) => setShowAnswer(checked)}
            />
          }
          label="Show answer"
          labelPlacement="start"
          sx={{ displayPrint: 'none' }}
        />
      </Box>

      {data.items.map((question, index) => (
        <Box sx={{ mb: 2, breakInside: 'avoid' }}>
          <Typography variant="h6" gutterBottom>
            Question {index + 1}
          </Typography>

          <QuestionCard
            text={question.text}
            images={question.images?.map(
              (filename: string) =>
                `${POCKETBASE_URL}/api/files/questions/${question.id}/${filename}`
            )}
            alternatives={question.expand?.alternatives}
            explanation={question.explanation}
            showAnswer={showAnswer}
            imagesFull={true}
          />
        </Box>
      ))}
    </Container>
  )
}

export default CollectionPrintPage
