import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useEffect } from 'react'

type TiptapEditorProps = {
  defaultValue?: string
  onChange?: (value: string) => void
  autofocus?: boolean
}

const TiptapEditor: React.FC<TiptapEditorProps> = ({
  defaultValue,
  onChange,
  autofocus = false,
}) => {
  const editor = useEditor({
    extensions: [StarterKit],
    editorProps: {
      attributes: {
        class:
          'prose max-w-none max-h-56 overflow-auto py-[16.5px] px-[14px] border border-[#c4c4c4] rounded hover:border-slate-800 focus:border-[#1976D2] focus:outline-none',
      },
    },
    content: defaultValue,
    autofocus,
    onUpdate: ({ editor }) => {
      onChange?.(editor.getHTML())
    },
  })

  useEffect(() => {
    if (defaultValue !== undefined && editor) {
      // Update editor content when defaultValue changes
      editor.commands.setContent(defaultValue)
    }
  }, [defaultValue])

  return <EditorContent editor={editor} />
}

export default TiptapEditor
