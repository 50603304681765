import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { pocketbase } from '../lib/config'
import { Link as RouterLink } from 'react-router-dom'
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material'
import { useState } from 'react'

const handlePasswordReset = async (email: string) => {
  return await pocketbase.collection('users').requestPasswordReset(email)
}

export default function PasswordResetPage() {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleError = (error: any) => {
    if (error.data.code === 400) {
      if (error.data.data.email !== undefined) {
        setErrors([
          ...errors.filter((e) => e !== error.data.data.email.message),
          error.data.data.email.message,
        ])
      }
    } else {
      setErrors([...errors.filter((e) => e !== error.message), error.message])
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    setLoading(true)
    try {
      await handlePasswordReset(data.get('email') as string)
      setDialogOpen(true)
    } catch (error) {
      handleError(error)
    }
    setLoading(false)
  }

  return (
    <Container component="main" maxWidth="xs">
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Password Reset Email Sent</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If an account exists with the email address you entered, you will
            receive an email with a link to reset your password.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <EnhancedEncryptionIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          Reset password
        </Typography>

        <Typography>
          Enter your email address and we'll send you a link to reset your
          password.
        </Typography>

        {!loading && errors && errors.length > 0 && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <Stack>
              {errors.map((error, index) => (
                <Typography key={index}>{error}</Typography>
              ))}
            </Stack>
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="E-mail"
            name="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Send password reset email'
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} variant="body2" to="/login">
                Log in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}
