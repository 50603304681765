import React from 'react'
import Lightbox from 'react-18-image-lightbox'

type ImageLightboxProps = {
  images: string[]
  index?: number
  onClose: () => void
}

const ImageLightbox: React.FC<ImageLightboxProps> = ({
  images,
  index,
  onClose,
}) => {
  const [imageIndex, setImageIndex] = React.useState<number>(index || 0)

  return (
    <Lightbox
      mainSrc={images[imageIndex]}
      nextSrc={images[(imageIndex + 1) % images.length]}
      prevSrc={images[(imageIndex + images.length - 1) % images.length]}
      onMovePrevRequest={() =>
        setImageIndex((imageIndex + images.length - 1) % images.length)
      }
      onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
      onCloseRequest={onClose}
      onImageLoad={() => window.dispatchEvent(new Event('resize'))}
    />
  )
}

export default ImageLightbox
