import { ListResult } from 'pocketbase'
import { pocketbase } from '../lib/config'
import { Question } from '../types/global'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  createQuestionUserMeta,
  updateQuestionUserMeta,
  UpdateQuestionUserMetaData,
} from './useQuestionUserMeta'
import { useSnackbar } from 'notistack'

export interface FilterParams {
  ids?: string[]
  collectionIds?: string[]
  tagIds?: string[]
  query?: string | null
}

interface PaginationParams {
  page?: number
  perPage?: number
}

export const getFilter = (params: FilterParams) => {
  let filter = ''
  if (params.query) {
    filter += `(text~"${params.query}"||alternatives.text?~"${params.query}")`
  }
  if (params.ids) {
    if (filter) {
      filter += '&&'
    }
    filter += `(${params.ids.map((id) => `id="${id}"`).join('||')})`
  }
  if (params.collectionIds) {
    if (filter) {
      filter += '&&'
    }
    filter += `(${params.collectionIds
      .map((id) => `collection="${id}"`)
      .join('||')})`
  }
  if (params.tagIds) {
    if (filter) {
      filter += '&&'
    }
    filter += `(${params.tagIds.map((id) => `tags.id?="${id}"`).join('||')})`
  }
  if (!filter) {
    return null
  }
  return `(${filter})`
}

const fetchQuestions = async ({ queryKey }: any) => {
  const params = queryKey[1] as FilterParams &
    PaginationParams & {
      expand?: string
    }
  const filter = getFilter(params)
  const expand = params.expand
    ? params.expand
    : 'alternatives,tags,question_user_meta(question)'
  const queryParams = {
    ...(filter && { filter }),
    expand,
  }
  return await pocketbase
    .collection('questions')
    .getList<Question>(params.page, params.perPage, queryParams)
}

export interface UpdateQuestionData {
  type?: 'text' | 'multiple' | 'single'
  text?: string
  explanation?: string
  points?: number
  images?: Record<string | number, File | ''>
  alternatives?: {
    id: string
  }[]
  tags?: {
    id: string
  }[]
}

const getFormData = (data: UpdateQuestionData) => {
  const formData = new FormData()

  if (data.type !== undefined) {
    formData.append('type', data.type)
  }
  if (data.text !== undefined) {
    formData.append('text', data.text)
  }
  if (data.explanation !== undefined) {
    formData.append('explanation', data.explanation)
  }
  if (data.points !== undefined) {
    formData.append('points', data.points.toString())
  }

  if (data.images !== undefined) {
    Object.keys(data.images).forEach((key) => {
      const value = data.images?.[key]
      if (value === undefined) {
        return
      }
      if (value === '') {
        // zero-value for deletion
        formData.append(`images.${key}`, '')
        return
      }
      formData.append('images', value)
    })
  }

  if (data.tags !== undefined) {
    if (data.tags.length === 0) {
      formData.append('tags', '')
    }
    for (let i = 0; i < data.tags.length; i++) {
      formData.append('tags', data.tags[i].id)
    }
  }

  if (data.alternatives !== undefined) {
    if (data.alternatives.length === 0) {
      formData.append('alternatives', '')
    }
    for (let i = 0; i < data.alternatives.length; i++) {
      formData.append('alternatives', data.alternatives[i].id)
    }
  }

  return formData
}

const updateQuestion = async (params: {
  id: string
  data: UpdateQuestionData
}) => {
  const formData = getFormData(params.data)
  return pocketbase
    .collection('questions')
    .update<Question>(params.id, formData, {
      expand: 'alternatives,tags,question_user_meta(question)',
    })
}

const deleteQuestion = async (id: string) => {
  return pocketbase.collection('questions').delete(id)
}

const useQuestions = (
  params: FilterParams &
    PaginationParams & {
      expand?: string
      enabled?: boolean
    }
) => {
  const queryKey = [
    'questions',
    {
      ids: params.ids,
      collectionIds: params.collectionIds,
      tagIds: params.tagIds,
      query: params.query,
      page: params.page,
      perPage: params.perPage,
      expand: params.expand,
    },
  ]

  const query = useQuery(queryKey, fetchQuestions, {
    enabled: params.enabled,
    keepPreviousData: true,
    staleTime: Infinity,
  })

  const user = pocketbase.authStore.model

  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const useUpdateQuestion = useMutation(updateQuestion, {
    onError: (error) => {
      enqueueSnackbar('Error updating question: ' + (error as any).message, {
        variant: 'error',
      })
    },
    onSuccess: (data) => {
      queryClient.setQueryData<ListResult<Question>>(
        queryKey,
        (oldData) =>
          oldData && {
            ...oldData,
            items: oldData.items.map((item) => {
              if (item.id === data.id) {
                return data
              }
              return item
            }),
          }
      )
    },
  })

  const useDeleteQuestion = useMutation(deleteQuestion, {
    onMutate: (questionId) => {
      queryClient.cancelQueries(queryKey)

      const previousData =
        queryClient.getQueryData<ListResult<Question>>(queryKey)

      queryClient.setQueryData<ListResult<Question>>(
        queryKey,
        (oldData) =>
          oldData && {
            ...oldData,
            items: oldData.items.filter((item) => item.id !== questionId),
          }
      )
      return { previousData }
    },
    onError: (error, questionId, context) => {
      queryClient.setQueryData(queryKey, context?.previousData)

      enqueueSnackbar('Error deleting question: ' + (error as any).message, {
        variant: 'error',
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
    },
  })

  const useUpdateQuestionUserMeta = useMutation(updateQuestionUserMeta, {
    onMutate: (data) => {
      queryClient.cancelQueries(queryKey)

      const previousData =
        queryClient.getQueryData<ListResult<Question>>(queryKey)

      queryClient.setQueryData<ListResult<Question>>(
        queryKey,
        (oldData) =>
          oldData && {
            ...oldData,
            items: oldData.items.map((item) => ({
              ...item,
              expand: {
                ...item.expand,
                'question_user_meta(question)':
                  item.expand?.['question_user_meta(question)'] &&
                  item.expand?.['question_user_meta(question)'].map(
                    (questionUserMeta) => {
                      if (questionUserMeta.id === data.id) {
                        return {
                          ...questionUserMeta,
                          ...data.data,
                        }
                      }
                      return questionUserMeta
                    }
                  ),
              },
            })),
          }
      )
      return { previousData }
    },
    onError: (error, data, context) => {
      queryClient.setQueryData(queryKey, context?.previousData)

      enqueueSnackbar('Error updating question: ' + (error as any).message, {
        variant: 'error',
      })
    },
  })

  const useCreateQuestionUserMeta = useMutation(createQuestionUserMeta, {
    onError: (error) => {
      enqueueSnackbar('Error updating question: ' + (error as any).message, {
        variant: 'error',
      })
    },
    onSuccess: (data) => {
      queryClient.setQueryData<ListResult<Question>>(
        queryKey,
        (oldData) =>
          oldData && {
            ...oldData,
            items: oldData.items.map((item) => {
              if (item.id === data.question) {
                return {
                  ...item,
                  expand: {
                    ...item.expand,
                    'question_user_meta(question)': [
                      ...(item.expand?.['question_user_meta(question)'] || []),
                      data,
                    ],
                  },
                }
              }
              return item
            }),
          }
      )
    },
  })

  const upsertQuestionUserMeta = async (params: {
    id?: string
    data: {
      questionId: string
    } & UpdateQuestionUserMetaData
  }) => {
    try {
      if (params.id !== undefined) {
        useUpdateQuestionUserMeta.mutate({
          id: params.id,
          data: {
            marked: params.data.marked,
          },
        })
        return
      }
      if (!user) {
        throw new Error('User not logged in')
      }
      useCreateQuestionUserMeta.mutate({
        user: user.id,
        question: params.data.questionId,
        marked: params.data.marked,
      })
    } catch (e: any) {
      enqueueSnackbar('Error updating question: ' + e.message, {
        variant: 'error',
      })
    }
  }

  return {
    ...query,
    useUpdateQuestion,
    useDeleteQuestion,
    upsertQuestionUserMeta,
  }
}

export default useQuestions
