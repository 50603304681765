import { pocketbase } from '../lib/config'
import { QuestionUserMeta } from '../types/global'

export interface UpdateQuestionUserMetaData {
  marked?: boolean
  flags?: string[]
  note?: string
}

export type CreateQuestionUserMetaData = {
  user: string
  question: string
} & Partial<UpdateQuestionUserMetaData>

export const createQuestionUserMeta = async (
  data: CreateQuestionUserMetaData
) => {
  return await pocketbase
    .collection('question_user_meta')
    .create<QuestionUserMeta>(data)
}

export const updateQuestionUserMeta = async (params: {
  id: string
  data: UpdateQuestionUserMetaData
}) => {
  return await pocketbase
    .collection('question_user_meta')
    .update<QuestionUserMeta>(params.id, params.data)
}
