import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import QuestionCard from './QuestionCard'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import TagIcon from '@mui/icons-material/LocalOffer'
import { POCKETBASE_URL } from '../lib/config'
import { Alternative, Collection, Question, Tag } from '../types/global'
import { UpdateQuestionUserMetaData } from '../util/useQuestionUserMeta'

type QuestionItemProps = {
  title?: string
  question: Question
  alternatives?: Alternative[]
  tags?: Tag[]
  tagOptions?: Tag[]
  collection?: Collection
  answer?: {
    alternativeIds?: string[]
    text?: string
  }
  showAnswer?: boolean
  highlightQuery?: string
  onEditQuestion?: () => void
  onUpdateQuestion?: (data: { tags: Tag[] }) => void
  onDeleteQuestion?: () => void
  onQuestionUserMetaChange?: (data: UpdateQuestionUserMetaData) => void
}

const QuestionItem: React.FC<QuestionItemProps> = (props) => {
  const meta = props.question.expand?.['question_user_meta(question)']?.[0]

  const [marked, setMarked] = React.useState(meta?.marked || false)
  const [showAnswer, setShowAnswer] = React.useState(props.showAnswer)
  const [editTags, setEditTags] = React.useState(false)

  const handleMarkQuestion = async (marked: boolean) => {
    setMarked(marked)
    props.onQuestionUserMetaChange?.({ marked })
  }

  const handleUpdateQuestion = (data: { tags: Tag[] }) => {
    setEditTags(false)
    if (
      data.tags !== undefined &&
      data.tags.map((tag) => tag.id).join(',') !==
        props.tags?.map((tag) => tag.id).join(',')
    ) {
      // Tags have changed
      props.onUpdateQuestion?.({ tags: data.tags })
    }
  }

  const handleDeleteQuestion = async () => {
    if (!confirm('Are you sure you want to delete this question?')) {
      return
    }
    props.onDeleteQuestion?.()
  }

  return (
    <Box>
      {props.title && (
        <Typography variant="h6" gutterBottom>
          {props.title}
        </Typography>
      )}

      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>
          <QuestionCard
            text={props.question.text}
            images={props.question.images?.map(
              (filename: string) =>
                `${POCKETBASE_URL}/api/files/questions/${props.question.id}/${filename}`
            )}
            alternatives={props.alternatives}
            explanation={props.question.explanation}
            tags={props.tags}
            collection={props.collection}
            tagOptions={props.tagOptions}
            answer={props.answer}
            highlightQuery={props.highlightQuery}
            showAnswer={showAnswer}
            editTags={editTags}
            onUpdateQuestion={handleUpdateQuestion}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
          <IconButton
            size="small"
            aria-label="bookmark"
            color={marked ? 'warning' : 'default'}
            onClick={() => handleMarkQuestion(!marked)}
          >
            {marked ? (
              <StarIcon fontSize="small" />
            ) : (
              <StarBorderIcon fontSize="small" />
            )}
          </IconButton>

          <IconButton
            size="small"
            aria-label="toggle"
            onClick={() => setShowAnswer(!showAnswer)}
          >
            {showAnswer ? (
              <VisibilityOffIcon fontSize="small" />
            ) : (
              <VisibilityIcon fontSize="small" />
            )}
          </IconButton>

          <IconButton
            size="small"
            aria-label="tag"
            onClick={() => setEditTags(true)}
          >
            <TagIcon
              fontSize="small"
              color={editTags ? 'primary' : 'inherit'}
            />
          </IconButton>

          <IconButton
            size="small"
            aria-label="edit"
            onClick={() => props.onEditQuestion?.()}
          >
            <EditIcon fontSize="small" />
          </IconButton>

          <IconButton
            size="small"
            aria-label="delete"
            onClick={handleDeleteQuestion}
            sx={(theme) => ({
              ':hover': {
                color: theme.palette.error.main,
              },
            })}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default QuestionItem
