import {
  Button,
  Container,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useFormik } from 'formik'
import { useMutation, useQuery } from '@tanstack/react-query'
import { pocketbase } from '../lib/config'
import { User } from '../types/global'

const fetchUser = async ({ queryKey }: any) => {
  const { id } = queryKey[1]
  return await pocketbase.collection('users').getOne<User>(id)
}

const SettingsPage = () => {
  const userId = pocketbase.authStore.model?.id

  const { data: user, isSuccess } = useQuery(
    ['user', { id: userId }],
    fetchUser
  )

  const formik = useFormik({
    initialValues: {
      email: user?.email,
      username: user?.username ?? '',
      avatar: undefined,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {}
      if (/ /.test(values.username)) {
        errors.username = 'Username cannot contain spaces'
      }
      if (values.username.length < 3) {
        errors.username = 'Username cannot be shorter than 3 characters'
      }
      if (values.username.length > 100) {
        errors.username = 'Username cannot be longer than 100 characters'
      }
      return errors
    },
    onSubmit: ({ username, avatar }) => {
      if (userId === undefined) return
      updateUser({
        userId: userId,
        username,
        avatar,
      })
    },
  })

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0] !== undefined) {
      formik.setFieldValue('avatar', e.target.files[0])
    }
  }

  const { mutate: updateUser, isLoading } = useMutation(
    (data: { userId: string; username?: string; avatar?: File | string }) => {
      const formData = new FormData()
      if (data.username !== undefined) {
        formData.append('username', data.username)
      }
      if (data.avatar !== undefined) {
        formData.append('avatar', data.avatar)
      }
      return pocketbase.collection('users').update(data.userId, formData)
    },
    {
      onSuccess: () => {
        window.location.reload()
      },
    }
  )

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>
      {isSuccess && (
        <Paper
          sx={{
            p: 3,
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2} sx={{ mb: 3 }}>
              <div>
                <TextField
                  defaultValue={user?.email}
                  label="E-mail"
                  name="email"
                  disabled
                ></TextField>
              </div>
              <div>
                <TextField
                  value={formik.values.username}
                  label="Username"
                  name="username"
                  onChange={formik.handleChange}
                ></TextField>
              </div>
              {formik.errors.username && (
                <div className="text-sm text-red-500">
                  {formik.errors.username}
                </div>
              )}
              <div>
                <Typography gutterBottom>Upload profile picture</Typography>
                <input type="file" accept="image/*" onChange={onSelectFile} />
                {userId !== undefined && user.avatar !== '' && (
                  <Button onClick={() => updateUser({ userId, avatar: '' })}>
                    Delete profile picture
                  </Button>
                )}
              </div>
            </Stack>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
            >
              Update
            </LoadingButton>
          </form>
        </Paper>
      )}
    </Container>
  )
}

export default SettingsPage
