import { useQuery } from '@tanstack/react-query'
import { pocketbase } from '../lib/config'

const fetchTag = async (id: string) => {
  return await pocketbase.collection('tags').getOne(id)
}

export default (tagId: string) => {
  const queryKey = ['tag', tagId]

  return useQuery(queryKey, () => fetchTag(tagId), {
    enabled: !!tagId,
  })
}
