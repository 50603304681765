import JSZip from 'jszip'
import { POCKETBASE_URL, pocketbase } from '../lib/config'
import { Question } from '../types/global'
import { formatAnkiCSV } from './formatAnkiCSV'

interface FilterParams {
  collectionId?: string
  tagId?: string
}

const fetchQuestions = async (params: { filter: FilterParams }) => {
  return await pocketbase
    .collection('questions')
    .getFullList<Question>(undefined, {
      filter: generateFilter(params.filter),
      expand: 'alternatives,tags',
    })
}

const generateFilter = (filter?: FilterParams) => {
  if (!filter) return undefined
  const filterParams = []
  if (filter.collectionId) {
    filterParams.push(['collection', `"${filter.collectionId}"`])
  }
  if (filter.tagId) {
    filterParams.push(['tags.id', `"${filter.tagId}"`])
  }
  return filterParams.map((param) => param.join('=')).join('&&')
}

export const downloadQuestions = async (
  filter: FilterParams,
  filename: string
) => {
  const data = await fetchQuestions({ filter })

  const csv = formatAnkiCSV(
    data.map((questionData) => ({
      ...questionData,
      alternatives: questionData.expand?.alternatives,
      tags: questionData.expand?.tags,
    }))
  )

  const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' })

  const zip = new JSZip()

  zip.file(`${filename}.csv`, csvBlob)

  const imageBlobs = await fetchImages(data)

  if (imageBlobs.length > 0) {
    const imagesFolder = zip.folder('images')

    imageBlobs.forEach((blob) => imagesFolder?.file((blob as any).name, blob))
  }

  const zipFile = await zip.generateAsync({ type: 'blob' })

  downloadBlob(zipFile, filename)
}

const fetchImages = (data: Question[]) => {
  const urls = []
  for (let i = 0; i < data.length; i++) {
    const questionData = data[i]
    if (questionData.images) {
      for (let j = 0; j < questionData.images.length; j++) {
        const filename = questionData.images[j]
        urls.push(
          `${POCKETBASE_URL}/api/files/questions/${questionData.id}/${filename}`
        )
      }
    }
  }

  return Promise.all(
    urls.map((url) =>
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          // Store the filename
          ;(blob as any).name = url.slice(url.lastIndexOf('/') + 1)
          return blob
        })
    )
  )
}

function downloadBlob(blob: Blob, filename: string) {
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
