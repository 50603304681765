import dayjs from 'dayjs'

export function canvasHtmlExportParser(doc: Document) {
  const dividerCharacter = '_'
  const questionTitleRegex = /Question\s*#:/
  const correctCharacter = '✓'
  const alternativeRegex = /^✓?[A-Z]{1}\./

  const section = doc.querySelector('section')
  const dividerChildrenIndices: number[] = []
  const sectionChildren = Array.from(section?.children ?? [])
  const examName = sectionChildren[0].innerHTML
    .replace('Kunskapsprovet för läkare utbildade utanför EU', '')
    .trim()
  sectionChildren.forEach((element, index) => {
    if (element.innerHTML.startsWith(dividerCharacter)) {
      dividerChildrenIndices.push(index)
    }
  })
  const questionElements = dividerChildrenIndices.map((value, index) => {
    const start = value + 1
    const end = dividerChildrenIndices[index + 1] ?? sectionChildren.length
    return sectionChildren.slice(start, end)
  })
  const questions = questionElements.map((elements) => {
    const figureElements: Element[] = []
    const textElements = elements.filter((element) => {
      if (questionTitleRegex.test(element.innerHTML)) {
        return false
      }
      if (element.tagName === 'FIGURE') {
        figureElements.push(element)
        return false
      }
      return !alternativeRegex.test(element.innerHTML)
    })
    const alternativeElements = elements.filter((element) =>
      alternativeRegex.test(element.innerHTML)
    )

    return {
      text: textElements
        .map((element) => {
          // if (element.tagName === 'UL') {
          //   // Canvas omits some of list item start content
          //   // so we need to guess what's missing
          //   const listItems = Array.from(element.children)
          //     .map((child) => {
          //       if (/^r /.test(child.innerHTML)) {
          //         return `<li>Nä${child.innerHTML}</li>`
          //       }
          //       if (/^typiska symtomen/.test(child.innerHTML)) {
          //         ;`<li>De ${child.innerHTML}</li>`
          //       }
          //       return `<li>Du ${child.innerHTML}</li>`
          //     })
          //     .join('')
          //   return `<ul>${listItems}</ul>`
          // }
          return element.outerHTML
        })
        .join(''),
      alternatives: alternativeElements.map((element) => ({
        text: element.innerHTML.replace(alternativeRegex, '').trim(),
        correct: element.innerHTML.startsWith(correctCharacter),
      })),
      images: figureElements
        .filter((element) => element.children[0]?.getAttribute('src'))
        .map((element) => ({
          url: element.children[0]?.getAttribute('src') ?? '',
        })),
    }
  })

  if (questions.length === 0) {
    return null
  }

  return [
    {
      name: `Import ${dayjs().format('YYYY-MM-DD')}`,
      collections: [
        {
          name: examName ?? undefined,
          questions: questions
            .filter((question) => question.alternatives.length > 0)
            .map((question) => ({
              type: 'multiple' as 'multiple',
              points: 1,
              text: question.text,
              alternatives: question.alternatives,
              ...(question.images && {
                images: question.images,
              }),
            })),
        },
      ],
    },
  ]
}
