import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Link,
} from '@mui/material'

type DonateDialogProps = {
  open: boolean
  onClose: () => void
}

const DonateDialog: React.FC<DonateDialogProps> = (props) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={props.open} onClose={props.onClose}>
      <DialogTitle>Viktigt meddelande</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ mb: 2 }}>
            Jag behöver hjälp med att täcka kostnaderna för att sidan ska
            fungera och därför har jag startat en Patreon. Följ länken för att
            läsa ett inlägg som jag skrivit. Stort tack!
          </Typography>
          <Link href="https://www.patreon.com/tentamera">
            patreon.com/tentamera
          </Link>
          <Link href="https://www.patreon.com/tentamera">
            <Box
              component="img"
              alt="Patreon Wordmark"
              src="patreon_wordmark.png"
            />
          </Link>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={props.onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DonateDialog
