import { Tag } from '../types/global'
import { createTag } from './useTags'

export const transformTags = async (tags: (Tag | string)[], userId: string) => {
  const output = []
  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i]
    if (typeof tag !== 'string') {
      output.push(tag)
      continue
    }
    const newTag = await createTag({ user: userId, name: tag })
    output.push(newTag)
  }
  return output
}
