import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { pocketbase } from '../lib/config'
import { QuizSession } from '../types/global'
import { createAnswer, fetchAnswers, updateAnswer } from './useAnswers'
import useQuestions from './useQuestions'

interface QuizAnswers {
  [questionId: string]: {
    id?: string
    created?: string
    updated?: string
    alternativeIds?: string[]
    text?: string
  }
}

const fetchQuizSession = async ({ queryKey }: any) => {
  const id = queryKey[1]
  return await pocketbase.collection('quiz_sessions').getOne<QuizSession>(id, {
    expand: 'quiz',
  })
}

interface UpdateQuizSessionData {
  finished?: boolean
  finished_at?: string
}

export const updateQuizSession = async (
  id: string,
  data: UpdateQuizSessionData
) => {
  return await pocketbase
    .collection('quiz_sessions')
    .update<QuizSession>(id, data)
}

export const getQuestionAnswerPoints = ({
  alternatives,
  alternativeIds,
  points,
}: {
  alternatives: { id: string; correct: boolean }[]
  alternativeIds: string[]
  points: number
}) => {
  const correctAlternativeIds = alternatives
    .filter((alternative: { correct: boolean }) => alternative.correct)
    .map((alternative: { id: string }) => alternative.id)

  const numberOfSelectedAlternatives = alternativeIds.length

  if (numberOfSelectedAlternatives > correctAlternativeIds.length) {
    // Too many alternatives selected
    return Math.max(
      0,
      points - (numberOfSelectedAlternatives - correctAlternativeIds.length)
    )
  }

  const correctSelectedAlternatives = correctAlternativeIds.filter(
    (correctAlternativeId: string) =>
      alternativeIds.includes(correctAlternativeId)
  )

  return Math.min(points, correctSelectedAlternatives.length)
}

export const getTotalPoints = (
  items: {
    id: string
    alternatives: {
      id: string
      correct: boolean
    }[]
    points: number
    answer?: {
      alternativeIds?: string[]
    }
  }[]
) => {
  return items.reduce((total, item) => {
    if (item.answer?.alternativeIds === undefined) {
      return total
    }
    return (
      total +
      getQuestionAnswerPoints({
        alternatives: item.alternatives,
        alternativeIds: item.answer.alternativeIds,
        points: item.points,
      })
    )
  }, 0)
}

export default ({ id: quizSessionId }: { id: string }) => {
  const quizSessionQueryKey = ['quizSession', quizSessionId]

  const {
    isLoading: quizSessionIsLoading,
    isError: quizSessionHasError,
    error: quizSessionError,
    data: quizSessionData,
    isSuccess: isQuizSessionSuccess,
  } = useQuery(quizSessionQueryKey, fetchQuizSession)

  const quiz = quizSessionData?.expand?.quiz
  const quizQuestionIds = quiz?.questions

  const {
    isLoading: questionsAreLoading,
    isError: questionsHaveError,
    error: questionsError,
    data: questionsData,
    isSuccess: questionsAreSuccess,
    useUpdateQuestion,
    useDeleteQuestion,
    upsertQuestionUserMeta,
  } = useQuestions({
    ids: quizQuestionIds,
    perPage: 500,
    enabled: !!quizQuestionIds,
  })

  const questions = questionsData?.items ?? []

  const answersQueryKey = ['answers', { quizSessionId }]

  const {
    isLoading: answersAreLoading,
    isError: answersHaveError,
    error: answersError,
    data: answersData,
    isSuccess: answersAreSuccess,
    refetch: refetchAnswers,
  } = useQuery(answersQueryKey, fetchAnswers)

  const [answers, setAnswers] = useState<QuizAnswers>({})

  useEffect(() => {
    if (answersData) {
      setAnswers(
        answersData.reduce((acc, answer) => {
          acc[answer.question] = {
            id: answer.id,
            alternativeIds: answer.alternatives,
            text: answer.text,
          }
          return acc
        }, {} as QuizAnswers)
      )
    }
  }, [answersData])

  const { enqueueSnackbar } = useSnackbar()

  const upsertAnswer = async (params: {
    questionId: string
    id?: string
    alternativeIds?: string[]
    text?: string
  }) => {
    setAnswers((answers) => ({
      ...answers,
      [params.questionId]: {
        ...answers[params.questionId],
        alternativeIds: params.alternativeIds,
        text: params.text,
      },
    }))

    if (params.id !== undefined) {
      await updateAnswer(params.id, {
        alternatives: params.alternativeIds,
        text: params.text,
      })
      return
    }

    try {
      const answer = await createAnswer({
        quiz_session: quizSessionId,
        question: params.questionId,
        alternatives: params.alternativeIds,
        text: params.text,
      })

      setAnswers((answers) => ({
        ...answers,
        [params.questionId]: {
          ...answers[params.questionId],
          id: answer.id,
        },
      }))
    } catch (e: any) {
      if (e.status === 400) {
        // UNIQUE constraint failed: answers.quiz_session, answers.question
        // Answer already exists, refetch answers to update local state
        refetchAnswers()
        enqueueSnackbar('Error updating answer: Answer already exists', {
          variant: 'error',
        })
      } else {
        enqueueSnackbar('Error updating answer: ' + e.message, {
          variant: 'error',
        })
      }
    }
  }

  const totalPoints = useMemo(
    () =>
      getTotalPoints(
        questions.map((question) => ({
          ...question,
          alternatives: question.expand?.alternatives || [],
          answer: answers[question.id],
        }))
      ),
    [questions, answers]
  )

  const maxTotalPoints = useMemo(
    () =>
      questions.reduce(
        (total: number, question: any) => total + (question.points || 1),
        0
      ),
    [questions]
  )

  const tags = useMemo(
    () =>
      questions
        .flatMap((question) => question.expand?.tags || [])
        .filter(
          // Remove duplicates
          (tag, index, self) => index === self.findIndex((t) => t.id === tag.id)
        )
        .map((tag) => {
          const tagQuestions = questions.filter((question) =>
            question.expand?.tags?.some((t) => t.id === tag.id)
          )
          const tagTotalPoints = getTotalPoints(
            tagQuestions.map((question) => ({
              ...question,
              alternatives: question.expand?.alternatives || [],
              answer: answers[question.id],
            }))
          )
          return {
            id: tag.id,
            name: tag.name,
            totalPoints: tagTotalPoints,
            questions: tagQuestions,
          }
        }) || [],
    [questions]
  )

  return {
    isLoading: quizSessionIsLoading || questionsAreLoading || answersAreLoading,
    isError: quizSessionHasError || questionsHaveError || answersHaveError,
    error: quizSessionError || questionsError || answersError,
    isSuccess: isQuizSessionSuccess && questionsAreSuccess && answersAreSuccess,
    quizSession: quizSessionData,
    quiz,
    questions: questionsData,
    useUpdateQuestion,
    useDeleteQuestion,
    upsertQuestionUserMeta,
    answers,
    upsertAnswer,
    totalPoints,
    maxTotalPoints,
    tags,
  }
}
