import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material'
import { useFormik } from 'formik'

type FolderDialogProps = {
  folder?: {
    id: string
    name: string
  }
  open: boolean
  submitLabel?: string
  onClose: () => void
  onSubmit?: (data: any) => void
}

const FolderDialog: React.FC<FolderDialogProps> = ({
  folder,
  open,
  submitLabel,
  onClose,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: {
      name: folder?.name ?? '',
    },
    onSubmit: async (values) => {
      onSubmit?.(values)
    },
  })

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">{submitLabel ?? 'Submit'}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default FolderDialog
