import { pocketbase } from '../lib/config'
import { Answer } from '../types/global'

interface CreateAnswerData {
  quiz_session: string
  question: string
  alternatives?: string[]
  text?: string
}

interface UpdateAnswerData {
  alternatives?: string[]
  text?: string
}

export const fetchAnswers = async ({ queryKey }: any) => {
  const { quizSessionId } = queryKey[1] as { quizSessionId: string }
  return await pocketbase.collection('answers').getFullList<Answer>(undefined, {
    filter: `quiz_session="${quizSessionId}"`,
  })
}

export const createAnswer = async (data: CreateAnswerData) => {
  return await pocketbase.collection('answers').create<Answer>(data)
}

export const updateAnswer = async (id: string, data: UpdateAnswerData) => {
  return await pocketbase.collection('answers').update<Answer>(id, data)
}
