import { Container, Typography, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

const NotFoundPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h5" gutterBottom>
        404 Not Found
      </Typography>

      <Typography gutterBottom>
        The page you are looking for does not exist.
      </Typography>

      <Link component={RouterLink} to="/">
        Go Home
      </Link>
    </Container>
  )
}

export default NotFoundPage
