import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './lib/config'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { CssBaseline } from '@mui/material'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import 'react-18-image-lightbox/style.css'

import DashboardLayout from './layouts/DashboardLayout'
import HomePage from './pages/HomePage'
import AuthPage from './pages/AuthPage'
import RegisterPage from './pages/RegisterPage'
import PasswordResetPage from './pages/PasswordResetPage'
import FolderPage from './pages/FolderPage'
import CollectionPage from './pages/CollectionPage'
import SearchPage from './pages/SearchPage'
import QuizzesPage from './pages/QuizzesPage'
import SettingsPage from './pages/SettingsPage'
import ImportPage from './pages/ImportPage'
import QuizPage from './pages/QuizPage'
import QuizResultPage from './pages/QuizResultPage'
import ProtectedRoute from './components/ProtectedRoute'
import NotFoundPage from './pages/NotFoundPage'
import StatisticsPage from './pages/StatisticsPage'
import CollectionPrintPage from './pages/CollectionPrintPage'
import TagsPage from './pages/TagsPage'
import TagPage from './pages/TagPage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <DashboardLayout></DashboardLayout>,
    children: [
      { path: '/', element: <HomePage></HomePage> },
      {
        path: '/folder/:id',
        element: (
          <ProtectedRoute>
            <FolderPage></FolderPage>
          </ProtectedRoute>
        ),
      },
      {
        path: '/collection/:id',
        element: (
          <ProtectedRoute>
            <CollectionPage></CollectionPage>
          </ProtectedRoute>
        ),
      },
      {
        path: '/tags',
        element: (
          <ProtectedRoute>
            <TagsPage></TagsPage>
          </ProtectedRoute>
        ),
      },
      {
        path: '/tag/:id',
        element: (
          <ProtectedRoute>
            <TagPage></TagPage>
          </ProtectedRoute>
        ),
      },
      {
        path: '/search',
        element: (
          <ProtectedRoute>
            <SearchPage></SearchPage>
          </ProtectedRoute>
        ),
      },
      {
        path: '/quiz',
        element: (
          <ProtectedRoute>
            <QuizzesPage></QuizzesPage>
          </ProtectedRoute>
        ),
      },
      {
        path: '/quiz/:id/result',
        element: (
          <ProtectedRoute>
            <QuizResultPage></QuizResultPage>
          </ProtectedRoute>
        ),
      },
      {
        path: '/import',
        element: (
          <ProtectedRoute>
            <ImportPage></ImportPage>
          </ProtectedRoute>
        ),
      },
      {
        path: '/statistics',
        element: (
          <ProtectedRoute>
            <StatisticsPage></StatisticsPage>
          </ProtectedRoute>
        ),
      },
      {
        path: '/settings',
        element: (
          <ProtectedRoute>
            <SettingsPage></SettingsPage>
          </ProtectedRoute>
        ),
      },
      {
        path: '*',
        element: <NotFoundPage></NotFoundPage>,
      },
    ],
  },
  { path: '/login', element: <AuthPage></AuthPage> },
  { path: '/register', element: <RegisterPage></RegisterPage> },
  { path: '/password-reset', element: <PasswordResetPage></PasswordResetPage> },
  {
    path: '/quiz/:id',
    element: (
      <ProtectedRoute>
        <QuizPage></QuizPage>
      </ProtectedRoute>
    ),
  },
  {
    path: '/collection/:id/print',
    element: (
      <ProtectedRoute>
        <CollectionPrintPage></CollectionPrintPage>
      </ProtectedRoute>
    ),
  },
])

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}

export default App
