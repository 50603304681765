import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  Stack,
  Typography,
  LinearProgress,
  DialogTitle,
  Alert,
  Box,
} from '@mui/material'
import { useFormik } from 'formik'
import { useQuery } from '@tanstack/react-query'
import { pocketbase } from '../lib/config'
import { Folder } from '../types/global'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const fetchFolders = async () => {
  return await pocketbase.collection('folders').getFullList<Folder>()
}

type ImportDialogProps = {
  folders?: {
    name?: string
    collections?: {
      name?: string
    }[]
  }[]
  loading?: boolean
  index: number
  totalQuestions: number
  progress?: number
  error?: string | null
  errors?: string[]
  open: boolean
  onClose?: () => void
  onSubmit?: ({
    folders,
  }: {
    folders?: { id?: string; collections: { id?: string }[] }[]
  }) => void
}

const ImportDialog: React.FC<ImportDialogProps> = (props) => {
  const { data: folders } = useQuery(['folders'], fetchFolders)

  const formik = useFormik({
    initialValues: {
      folders: props.folders?.map((folder) => {
        // look for existing folders with imported folder names
        const foundFolder = folders?.find((f) => f.name === folder.name)
        if (foundFolder !== undefined) {
          return {
            id: foundFolder.id,
            name: foundFolder.name,
          }
        }
        return null
      }),
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      props.onSubmit?.({ folders: values.folders as any })
    },
  })

  return (
    <Dialog open={props.open} fullWidth maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Import options</DialogTitle>

        <DialogContent>
          {props.error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {props.error}
            </Alert>
          )}

          <Stack spacing={2}>
            <Typography>
              Do you want to import the data into an existing folder? Leave
              empty to create a new folder.
            </Typography>

            {props.folders?.map((folder, index) => (
              <Stack
                key={index}
                spacing={2}
                direction="row"
                sx={{ alignItems: 'center' }}
              >
                <Typography fontWeight={500}>{folder.name}</Typography>

                <ArrowForwardIcon />

                <Autocomplete
                  size="small"
                  sx={{ flexGrow: 1 }}
                  options={folders || []}
                  value={formik.values.folders?.[index]}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue(`folders.[${index}]`, value)
                  }}
                  getOptionLabel={(option) => option.name as string}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        <Typography>{option.name}</Typography>
                      </li>
                    )
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select folder" />
                  )}
                />
              </Stack>
            ))}

            {props.loading && (
              <Box>
                <LinearProgress
                  variant="determinate"
                  value={props.progress}
                ></LinearProgress>
                <Typography variant="caption">
                  {props.index + 1} / {props.totalQuestions}
                </Typography>
              </Box>
            )}

            {!props.loading &&
              props.errors !== undefined &&
              props.errors?.length > 0 && (
                <Alert severity="warning">
                  There were some errors during the import. However, most of the
                  data was probably imported successfully. You can now close
                  this dialog.
                </Alert>
              )}

            {props.errors !== undefined && props.errors.length > 0 && (
              <Alert severity="error">
                {props.errors.map((error, index) => (
                  <Typography key={index} variant="body2">
                    {error}
                  </Typography>
                ))}
              </Alert>
            )}
          </Stack>
        </DialogContent>

        {!props.loading && props.errors?.length === 0 && (
          <DialogActions>
            <Button onClick={props.onClose}>Close</Button>
            <Button type="submit">Import</Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  )
}

export default ImportDialog
