import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField } from '@mui/material'
import { useState } from 'react'

type BasicDatePickerProps = {
  value: Date | null
  label?: string
  onChange?: (newValue: Date | null) => void
}

const BasicDatePicker: React.FC<BasicDatePickerProps> = (props) => {
  const [value, setValue] = useState(props.value)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={props.label}
        value={value}
        onChange={(newValue) => {
          setValue(newValue)
          props.onChange?.(newValue)
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  )
}

export default BasicDatePicker
