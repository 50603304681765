import { TextField } from '@mui/material'
import React from 'react'
import { ExportData } from '../types/export'

type ImportParserFormProps = {
  onSuccess: (params: { data: ExportData }) => void
  onError: (error: any) => void
}

const ImportParserForm: React.FC<ImportParserFormProps> = ({
  onSuccess,
  onError,
}) => {
  const [value, setValue] = React.useState<string>()

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(event.target.value)
  }

  React.useEffect(() => {
    if (value) {
      const timeout = setTimeout(() => {
        parse(value)
      }, 200)

      return () => clearTimeout(timeout)
    }
  }, [value])

  const parse = (value: string) => {
    try {
      const exportData = JSON.parse(value)
      onSuccess?.({ data: exportData })
    } catch (e) {
      if (e instanceof SyntaxError) {
        return onError(new Error('Invalid JSON'))
      }
      onError(e)
    }
  }

  return (
    <TextField
      fullWidth
      multiline
      minRows={6}
      maxRows={10}
      value={value}
      onChange={handleChange}
    />
  )
}

export default ImportParserForm
