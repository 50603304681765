import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Button,
  Box,
} from '@mui/material'
import FolderIcon from '@mui/icons-material/Folder'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { pocketbase } from '../lib/config'
import FolderDialog from './FolderDialog'
import useFolders from '../util/useFolders'

interface CreateFolderData {
  name: string
  user: string
}

const createFolder = async (data: CreateFolderData) => {
  return await pocketbase.collection('folders').create(data)
}

const FolderCollapseListItem: React.FC = () => {
  const [open, setOpen] = React.useState(true)
  const [folderDialogOpen, setFolderDialogOpen] = React.useState(false)
  const [maxFolderLength, setMaxFolderLength] = React.useState(3)

  const { data: folders, isSuccess, refetch: refetchFolders } = useFolders()

  const routerLocation = useLocation()

  const handleCreateFolder = async (data: CreateFolderData) => {
    const userId = pocketbase.authStore.model?.id
    if (!userId) return
    await createFolder({ ...data, user: userId })
    refetchFolders()
    setFolderDialogOpen(false)
  }

  return (
    <>
      <FolderDialog
        open={folderDialogOpen}
        submitLabel="Create folder"
        onClose={() => setFolderDialogOpen(false)}
        onSubmit={handleCreateFolder}
      />

      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText primary="Folders" />
        {isSuccess && open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      {isSuccess && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{ maxHeight: '400px' }}
        >
          <List component="div" disablePadding sx={{ mb: 1 }}>
            {folders?.slice(0, maxFolderLength).map((folder) => (
              <ListItemButton
                key={folder.id}
                component={RouterLink}
                to={`/folder/${folder.id}`}
                selected={routerLocation.pathname === `/folder/${folder.id}`}
                sx={{ pl: 4 }}
              >
                <ListItemText primary={folder.name} className="truncate" />
              </ListItemButton>
            ))}
            {folders.length > maxFolderLength && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => setMaxFolderLength(folders.length)}
                dense
              >
                <ListItemText
                  primary={`Visa ${folders.length - maxFolderLength} till`}
                  className="truncate"
                />
              </ListItemButton>
            )}
          </List>

          <Box sx={{ pl: 3 }}>
            <Button onClick={() => setFolderDialogOpen(true)}>
              New folder
            </Button>
          </Box>
        </Collapse>
      )}
    </>
  )
}

export default FolderCollapseListItem
