import { pocketbase } from '../lib/config'
import { Alternative } from '../types/global'

export interface CreateAlternativeData {
  text: string
  correct: boolean
  explanation?: string
}

export interface UpdateAlternativeData {
  text?: string
  correct?: boolean
  explanation?: string
}

export const createAlternative = async (data: CreateAlternativeData) => {
  return await pocketbase.collection('alternatives').create<Alternative>(data)
}

export const updateAlternative = async (
  id: string,
  data: UpdateAlternativeData
) => {
  return await pocketbase
    .collection('alternatives')
    .update<Alternative>(id, data)
}

export const deleteAlternative = async (id: string) => {
  return await pocketbase.collection('alternatives').delete(id)
}
