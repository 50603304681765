import { pocketbase } from '../lib/config'
import { Quiz, QuizSession } from '../types/global'
import getRandom from './getRandom'

export const createQuiz = async (data: {
  questionIds: string[]
  maxQuestions: number
  disableRandomQuestionOrder?: boolean
  disableShowAnswer?: boolean
  userId: string
  name?: string
}) => {
  const isRandomQuestionOrder = !data.disableRandomQuestionOrder ?? true

  const quiz = await pocketbase.collection('quizzes').create<Quiz>({
    questions: isRandomQuestionOrder
      ? getRandom(
          data.questionIds,
          Math.min(data.maxQuestions, data.questionIds.length)
        )
      : data.questionIds.slice(0, data.maxQuestions),
    user: data.userId,
    name: data.name,
    disable_show_answer: data.disableShowAnswer,
  })

  const quizSession = pocketbase
    .collection('quiz_sessions')
    .create<QuizSession>({ quiz: quiz.id })

  return quizSession
}
