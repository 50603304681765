import { IconButton, Menu, MenuItem, Avatar, ListItemIcon } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import LogoutIcon from '@mui/icons-material/Logout'
import UserIcon from '@mui/icons-material/AccountCircle'
import React from 'react'
import { pocketbase, POCKETBASE_URL } from '../lib/config'
import { Link as RouterLink } from 'react-router-dom'

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: (name.split(' ').length > 1
      ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
      : `${name.split(' ')[0][0]}`
    ).toUpperCase(),
  }
}

const handleLogout = () => {
  pocketbase.authStore.clear()
  window.location.href = '/login'
}

type UserMenuButtonProps = {
  user: {
    id: string
    username?: string
    avatar?: string
  }
}

const UserMenuButton: React.FC<UserMenuButtonProps> = ({
  user: { id: userId, username, avatar },
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="inherit"
      >
        {username !== undefined ? (
          <Avatar
            alt={username}
            {...stringAvatar(username)}
            src={
              avatar
                ? `${POCKETBASE_URL}/api/files/users/${userId}/${avatar}`
                : ''
            }
          />
        ) : (
          <UserIcon />
        )}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem component={RouterLink} to="/settings">
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenuButton
