import { useQuery, useQueryClient } from '@tanstack/react-query'
import { pocketbase } from '../lib/config'
import { Collection } from '../types/global'

const fetchCollection = async (id: string) => {
  return await pocketbase.collection('collections').getOne<Collection>(id, {
    expand: 'folder',
  })
}

export default (collectionId: string) => {
  const queryKey = ['collection', collectionId]

  const queryClient = useQueryClient()

  const invalidateQueries = () => {
    queryClient.invalidateQueries(queryKey)
  }

  const query = useQuery(queryKey, () => fetchCollection(collectionId), {
    enabled: !!collectionId,
  })

  return {
    ...query,
    invalidateQueries,
  }
}
