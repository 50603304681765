import { useQuery } from '@tanstack/react-query'
import { pocketbase } from '../lib/config'

const fetchFolder = async (id: string) => {
  return await pocketbase.collection('folders').getOne(id)
}

export default (folderId: string) => {
  const queryKey = ['folder', folderId]

  return useQuery(queryKey, () => fetchFolder(folderId), {
    enabled: !!folderId,
  })
}
