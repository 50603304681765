import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
  Autocomplete,
  TextField,
  Alert,
  Collapse,
  Tabs,
  Tab,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ImportDialog from '../components/ImportDialog'
import ImportParserForm from '../components/ImportParserForm'
import OrtracImportInstructions from '../components/OrtracImportInstructions'
import QuestionCard from '../components/QuestionCard'
import { ExportData, Collection as ExportCollection } from '../types/export'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useQueryClient } from '@tanstack/react-query'
import { importExportData, parseExportFile } from '../util/useImport'
import { pocketbase } from '../lib/config'
import { LoadingButton } from '@mui/lab'
import { JSZipObject } from 'jszip'
import useTags from '../util/useTags'
import cuid from 'cuid'

const ImportPage = () => {
  const [exportData, setExportData] = useState<ExportData>()
  const [imageFiles, setImageFiles] = useState<JSZipObject[]>()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [parseError, setParseError] = useState<string | null>(null)
  const [parseFileLoading, setParseFileLoading] = useState(false)
  const [selectedPreviewCollection, setSelectedPreviewCollection] =
    useState<ExportCollection | null>()
  const [showInstructions, setShowInstructions] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [showAnswer, setShowAnswer] = useState(false)
  const [importDialogOpen, setImportDialogOpen] = useState(false)
  const [importLoading, setImportLoading] = useState(false)
  const [importIndex, setImportIndex] = useState(0)
  const [importTotalQuestions, setImportTotalQuestions] = useState(0)
  const [importProgress, setImportProgress] = useState(0)
  const [importError, setImportError] = useState<string | null>(null)
  const [importErrors, setImportErrors] = useState<string[]>([])

  const handleParseError = (error: any) => {
    setParseError(error.message)
  }

  const handleParseSuccess = (params: {
    data: ExportData
    imageFiles?: JSZipObject[]
  }) => {
    setParseError(null)
    setExportData(params.data)
    setImageFiles(params.imageFiles)
    if (params.data.folders?.[0]?.collections?.[0]) {
      setSelectedPreviewCollection(params.data.folders[0].collections[0])
    }
  }

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setExportData(undefined)
      setSelectedFile(file)
    }
  }

  const handleParseFile = async () => {
    if (selectedFile) {
      setParseFileLoading(true)
      const data = await parseExportFile(selectedFile)
      if (data?.data === undefined) {
        handleParseError({
          message:
            'Invalid file. Allowed file types: ".tent", ".json", ".html".',
        })
        setParseFileLoading(false)
        return
      }
      handleParseSuccess({ data: data.data, imageFiles: data.imageFiles })
      setParseFileLoading(false)
    }
  }

  const { data: tags } = useTags()

  const queryClient = useQueryClient()

  const navigate = useNavigate()

  const handleImport = async ({
    folders,
  }: {
    folders?: { id?: string; collections: { id?: string }[] }[]
  }) => {
    const userId = pocketbase.authStore.model?.id

    if (userId && exportData) {
      setImportError(null)
      setImportLoading(true)
      setImportIndex(0)
      setImportTotalQuestions(0)
      setImportProgress(0)

      const totalQuestions = exportData.folders.reduce(
        (acc, folder) =>
          acc +
          (folder.collections ?? []).reduce(
            (acc, collection) => acc + collection.questions.length,
            0
          ),
        0
      )

      setImportTotalQuestions(totalQuestions)

      try {
        const data = await importExportData({
          userId,
          data: exportData,
          imageFiles,
          totalQuestions,
          selectedFolders: folders,
          tagOptions: tags,
          onProgress: ({ index, progress }) => {
            setImportIndex(index)
            setImportProgress(progress)
          },
          onError: ({ index, error, message }) => {
            setImportErrors((errors) => [...errors, message])
          },
        })

        let redirectTo = `/folder/${data.folderIds[0]}`
        if (data.collectionIds.length === 1) {
          redirectTo = `/collection/${data.collectionIds[0]}`
        }

        queryClient.invalidateQueries(['folders'])

        setImportLoading(false)

        // TODO: do not navigate if there are import errors
        if (importErrors.length === 0) {
          navigate(redirectTo ?? '/')
        }
      } catch (error: any) {
        setImportError(error.message)
        setImportLoading(false)
      }
    }
  }

  const collectionOptions = useMemo(
    () =>
      (exportData?.folders || [])
        .filter((folder) => folder.collections && folder.collections.length > 0)
        .flatMap((folder, folderIndex) =>
          folder.collections!.map((collection, collectionIndex) => ({
            ...collection,
            name: collection.name ?? `Collection ${collectionIndex + 1}`,
            folder: folder.name ?? `Folder ${folderIndex + 1}`,
          }))
        ),
    [exportData]
  )

  const questionLength = useMemo(
    () =>
      (exportData?.folders || [])
        .filter((folder) => folder.collections && folder.collections.length > 0)
        .flatMap((folder) => folder.collections!)
        .reduce((acc, collection) => acc + collection.questions.length, 0),
    [exportData]
  )

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <ImportDialog
        folders={exportData?.folders}
        loading={importLoading}
        index={importIndex}
        totalQuestions={importTotalQuestions}
        progress={importProgress}
        error={importError}
        errors={importErrors}
        open={importDialogOpen}
        onClose={() => setImportDialogOpen(false)}
        onSubmit={handleImport}
      />

      <Paper sx={{ mb: 3 }}>
        <Tabs
          value={tabIndex}
          onChange={(e, value) => setTabIndex(value)}
          sx={{ px: 1, pt: 1 }}
        >
          <Tab label="Text / Ortrac" />
          <Tab label="File" />
        </Tabs>

        {parseError && (
          <Box sx={{ p: 3 }}>
            <Alert severity="error">{parseError}</Alert>
          </Box>
        )}

        <TabPanel value={tabIndex} index={0}>
          <Box sx={{ mb: 1 }}>
            <Button
              onClick={() => setShowInstructions((prev) => !prev)}
              startIcon={
                showInstructions ? <ExpandLessIcon /> : <ExpandMoreIcon />
              }
              sx={{ ml: -1 }}
            >
              Importera från Ortrac – Instruktioner
            </Button>

            <Collapse in={showInstructions}>
              <div className="prose">
                <OrtracImportInstructions />
              </div>
            </Collapse>
          </Box>

          <Box mb={2}>
            <Typography gutterBottom>Copy and Paste your data here.</Typography>
            <ImportParserForm
              onSuccess={handleParseSuccess}
              onError={handleParseError}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              variant="contained"
              onClick={() => setImportDialogOpen(true)}
              disabled={
                exportData === undefined ||
                questionLength === 0 ||
                importLoading ||
                !!parseError
              }
            >
              Import
            </Button>
          </Box>
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          <Typography gutterBottom>Select a file to import.</Typography>
          <Box sx={{ my: 2 }}>
            <input
              type="file"
              onChange={handleSelectFile}
              disabled={parseFileLoading}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            {!exportData && (
              <LoadingButton
                variant="contained"
                onClick={handleParseFile}
                loading={parseFileLoading}
                disabled={!selectedFile}
                sx={{ mr: 2 }}
              >
                Preview
              </LoadingButton>
            )}

            <Button
              variant="contained"
              disabled={!exportData}
              onClick={() => setImportDialogOpen(true)}
            >
              Import
            </Button>
          </Box>
        </TabPanel>
      </Paper>

      {exportData && (
        <>
          <Typography variant="h5" gutterBottom>
            Preview
          </Typography>

          {collectionOptions.length > 1 ? (
            <Autocomplete
              fullWidth
              options={collectionOptions}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              groupBy={(option: any) => option.folder}
              getOptionLabel={(option: any) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Select collection" />
              )}
              value={selectedPreviewCollection}
              onChange={(e, value) => {
                setSelectedPreviewCollection(value)
              }}
              sx={{ mb: 3 }}
            />
          ) : (
            <Typography fontWeight="500" gutterBottom>
              {selectedPreviewCollection?.name}
            </Typography>
          )}

          {questionLength === 0 && (
            <Alert severity="info">No questions found</Alert>
          )}
        </>
      )}

      {exportData &&
        !!selectedPreviewCollection &&
        selectedPreviewCollection.questions.length > 0 && (
          <>
            <Box sx={{ display: 'flex', mb: 2 }}>
              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'end' }}>
                <Typography variant="body1">
                  {selectedPreviewCollection.questions.length} questions
                </Typography>
              </Box>

              <FormControlLabel
                sx={{ mr: 0 }}
                control={
                  <Switch
                    onChange={(event, checked) => setShowAnswer(checked)}
                  />
                }
                label="Show answer"
              />
            </Box>

            <Stack spacing={2}>
              {selectedPreviewCollection.questions.map((question, index) => (
                <QuestionCard
                  key={index}
                  text={question.text ?? ''}
                  images={question.images?.map((image) => image.url)}
                  tags={
                    question.tags?.map((name) => ({ id: cuid(), name })) as any
                  }
                  alternatives={question.alternatives?.map((alternative) => ({
                    ...alternative,
                    correct: alternative.correct ?? false,
                  }))}
                  explanation={question.explanation}
                  showAnswer={showAnswer}
                />
              ))}
            </Stack>
          </>
        )}
    </Container>
  )
}

function TabPanel(props: {
  children?: React.ReactNode
  index: number
  value: number
}) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

export default ImportPage
