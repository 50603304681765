import { Container, Paper } from '@mui/material'
import QuizzesTable from '../components/QuizzesTable'

const QuizzesPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper>
        <QuizzesTable />
      </Paper>
    </Container>
  )
}

export default QuizzesPage
