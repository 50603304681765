import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { useState } from 'react'
import { pocketbase } from '../lib/config'

type FeedbackDialogProps = {
  open: boolean
  onClose: () => void
}

const createReport = async (data: {
  message: string
  user: string
  path: string
}) => {
  return await pocketbase.collection('reports').create(data)
}

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validate: (values) => {
      const errors: any = {}
      if (!values.message) {
        errors.message = 'Message is required'
      }
      return errors
    },
    onSubmit: async (values) => {
      const user = pocketbase.authStore.model
      if (!user) return
      setLoading(true)
      try {
        await createReport({
          message: values.message,
          user: user.id,
          path: window.location.pathname,
        })
        setSuccess(true)
      } catch (e: any) {
        setError(e.message)
      }
      setLoading(false)
    },
  })

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      if (success) {
        formik.resetForm()
      }
      setSuccess(false)
      setError('')
    }, 500)
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Feedback</DialogTitle>

        {!success ? (
          <>
            <DialogContent>
              {error && <Alert color="error">{error}</Alert>}

              <Typography sx={{ mb: 2 }}>
                Skriv gärna om du har förslag på förbättringar, nya funktioner
                eller har hittat något fel.
              </Typography>

              <TextField
                autoFocus
                fullWidth
                multiline
                label="Message"
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
              />

              {formik.errors.message && (
                <Typography variant="body2" color="error">
                  {formik.errors.message}
                </Typography>
              )}
            </DialogContent>

            <DialogActions>
              <Button type="button" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton type="submit" loading={loading}>
                Submit
              </LoadingButton>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <Alert color="success">Tack för din feedback!</Alert>
            </DialogContent>

            <DialogActions>
              <Button type="button" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </form>
    </Dialog>
  )
}

export default FeedbackDialog
