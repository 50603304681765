import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Slider,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Box,
  Stack,
  Collapse,
} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { pocketbase } from '../lib/config'
import { Collection, Question, Tag } from '../types/global'
import { createQuiz } from '../util/useQuizzes'

const fetchQuestions = async ({ queryKey }: any) => {
  const { folderId, collectionIds, tagIds } = queryKey[1] as {
    folderId: string
    collectionIds: string[]
    tagIds: string[]
  }
  const filters = []
  if (folderId) {
    filters.push(`collection.folder="${folderId}"`)
  }
  if (collectionIds.length > 0) {
    filters.push(collectionIds.map((id) => `collection="${id}"`).join('||'))
  }
  if (tagIds.length > 0) {
    filters.push(tagIds.map((id) => `tags.id?="${id}"`).join('||'))
  }
  return await pocketbase
    .collection('questions')
    .getFullList<Question>(undefined, {
      ...(filters.length > 0 && {
        filter: `(${filters.map((filter) => `(${filter})`).join('&&')})`,
        expand: 'question_user_meta(question),answers(question).alternatives',
      }),
    })
}

type QuizDialogProps = {
  open: boolean
  onClose: () => void
  folderId: string
  collections?: Collection[]
  tags?: Tag[]
  selectedCollectionIds?: string[]
  selectedTagIds?: string[]
}

const QuizDialog: React.FC<QuizDialogProps> = ({
  open,
  onClose,
  folderId,
  collections,
  tags,
  selectedCollectionIds = [],
  selectedTagIds = [],
}) => {
  const navigate = useNavigate()

  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)

  const formik = useFormik({
    initialValues: {
      selectedCollectionIds,
      selectedTagIds,
      marked: false,
      unanswered: false,
      incorrect: false,
      disableRandomQuestionOrder: false,
      disableShowAnswer: false,
      maxQuestions: 10,
    },
    onSubmit: async ({
      maxQuestions,
      disableRandomQuestionOrder,
      disableShowAnswer,
    }) => {
      const userId = pocketbase.authStore.model?.id
      if (
        filteredQuestionIds.length === 0 ||
        maxQuestions < 1 ||
        userId === undefined
      ) {
        return
      }
      const quizSession = await createQuiz({
        questionIds: filteredQuestionIds,
        maxQuestions,
        userId,
        name: generateQuizName(),
        disableRandomQuestionOrder,
        disableShowAnswer,
      })
      navigate(`/quiz/${quizSession.id}`)
    },
  })

  const questionsQueryKey = useMemo(
    () => [
      'questions',
      {
        folderId,
        collectionIds: formik.values.selectedCollectionIds,
        tagIds: formik.values.selectedTagIds,
      },
    ],
    [formik.values.selectedCollectionIds, formik.values.selectedTagIds]
  )

  const { data: questions } = useQuery(questionsQueryKey, fetchQuestions, {
    enabled:
      open &&
      !!folderId &&
      (formik.values.selectedCollectionIds.length > 0 ||
        formik.values.selectedTagIds.length > 0),
    staleTime: 1000 * 60,
  })

  useEffect(() => {
    if (formik.values.unanswered) {
      formik.setFieldValue('incorrect', false)
    }
  }, [formik.values.unanswered])

  useEffect(() => {
    if (formik.values.incorrect) {
      formik.setFieldValue('unanswered', false)
    }
  }, [formik.values.incorrect])

  const filteredQuestions = useMemo(
    () =>
      questions?.filter((question) => {
        if (formik.values.marked) {
          return question.expand?.['question_user_meta(question)']?.[0].marked
        }
        if (formik.values.unanswered) {
          return question.expand?.['answers(question)'] === undefined
        }
        if (formik.values.incorrect) {
          const answers = question.expand?.['answers(question)']
          return (
            answers &&
            answers.some((answer) =>
              answer.expand?.['alternatives']?.some(
                (alternative) => !alternative.correct
              )
            )
          )
        }
        return true
      }),
    [
      questions,
      formik.values.marked,
      formik.values.unanswered,
      formik.values.incorrect,
    ]
  )

  const filteredQuestionIds = useMemo(() => {
    if (!filteredQuestions) return []
    return filteredQuestions.map((question) => question.id)
  }, [filteredQuestions])

  const getCollectionLabel = (id: string) => {
    const collection = collections?.find((collection) => collection.id === id)
    return `${collection?.name}`
  }

  const getTagLabel = (id: string) => {
    const tag = tags?.find((tag) => tag.id === id)
    return `${tag?.name}`
  }

  const generateQuizName = () => {
    const collectionNames = formik.values.selectedCollectionIds
      .map((id) => getCollectionLabel(id))
      .join(', ')
    const tagNames = formik.values.selectedTagIds
      .map((id) => getTagLabel(id))
      .join(', ')
    return `${collectionNames}${
      collectionNames && tagNames ? '; ' : ''
    }${tagNames}`
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel>Collections</InputLabel>
              <Select
                multiple
                autoWidth
                label="Collections"
                name="selectedCollectionIds"
                value={formik.values.selectedCollectionIds}
                onChange={formik.handleChange}
                input={<OutlinedInput label="Collections" />}
                renderValue={(selected) =>
                  selected.map((id) => getCollectionLabel(id)).join(', ')
                }
              >
                {collections?.map((collection) => (
                  <MenuItem key={collection.id} value={collection.id}>
                    <Checkbox
                      checked={
                        formik.values.selectedCollectionIds?.indexOf(
                          collection.id
                        ) > -1
                      }
                    />
                    <ListItemText primary={collection.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Tags</InputLabel>
              <Select
                multiple
                autoWidth
                label="Tags"
                name="selectedTagIds"
                value={formik.values.selectedTagIds}
                onChange={formik.handleChange}
                input={<OutlinedInput label="Tags" />}
                renderValue={(selected) =>
                  selected.map((id) => getTagLabel(id)).join(', ')
                }
              >
                {tags?.map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    <Checkbox
                      checked={
                        formik.values.selectedTagIds?.indexOf(tag.id) > -1
                      }
                    />
                    <ListItemText primary={tag.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Box sx={{ my: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="marked"
                  checked={formik.values.marked}
                  onChange={formik.handleChange}
                />
              }
              label="Bookmarked"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="unanswered"
                  checked={formik.values.unanswered}
                  onChange={formik.handleChange}
                />
              }
              label="Unanswered"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="incorrect"
                  checked={formik.values.incorrect}
                  onChange={formik.handleChange}
                />
              }
              label="Incorrect"
            />
          </Box>

          <Typography>Max questions</Typography>
          <Slider
            aria-label="Max questions"
            getAriaValueText={(value) => `${value} questions`}
            valueLabelDisplay={filteredQuestionIds?.length ? 'on' : 'off'}
            min={0}
            max={filteredQuestionIds?.length || 0}
            value={formik.values.maxQuestions}
            onChange={(e, value) => formik.setFieldValue('maxQuestions', value)}
            disabled={!filteredQuestionIds?.length}
          />

          <Button
            size="small"
            onClick={() => setShowAdvancedSettings((prev) => !prev)}
            endIcon={
              showAdvancedSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />
            }
          >
            Advanced Settings
          </Button>

          <Collapse in={showAdvancedSettings}>
            <FormControlLabel
              control={
                <Checkbox
                  name="disableRandomQuestionOrder"
                  checked={formik.values.disableRandomQuestionOrder}
                  onChange={formik.handleChange}
                />
              }
              label="Disable random question order"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="disableShowAnswer"
                  checked={formik.values.disableShowAnswer}
                  onChange={formik.handleChange}
                />
              }
              label="Disable show answer"
            />
          </Collapse>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="submit"
            disabled={
              filteredQuestionIds?.length === 0 ||
              formik.values.maxQuestions < 1
            }
          >
            Create quiz
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default QuizDialog
