import {
  Alert,
  AppBar,
  CircularProgress,
  Container,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material'
import useTags from '../util/useTags'
import { Link as RouterLink } from 'react-router-dom'

const TagsPage = () => {
  const { isLoading, data: tags, isSuccess, isError, error } = useTags()

  return (
    <>
      <AppBar position="static" color="inherit">
        <Toolbar>
          <Typography color="text.primary" fontWeight={500}>
            Tags
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {isLoading && <CircularProgress />}

        {isError && (
          <Alert severity="error">
            Error fetching tags: {(error as any).message}
          </Alert>
        )}

        {isSuccess && (
          <>
            <Paper sx={{ mb: 2 }}>
              <List subheader={<ListSubheader>Tags</ListSubheader>}>
                {tags?.map((tag) => (
                  <ListItemButton
                    key={tag.id}
                    component={RouterLink}
                    to={`/tag/${tag.id}`}
                  >
                    <ListItemText primary={tag.name} />
                  </ListItemButton>
                ))}
              </List>
            </Paper>
          </>
        )}
      </Container>
    </>
  )
}

export default TagsPage
