import {
  Alert,
  AppBar,
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Toolbar,
  Typography,
  Link,
  Button,
  LinearProgress,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
  Autocomplete,
  TextField,
} from '@mui/material'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import useQuizSession, { getQuestionAnswerPoints } from '../util/useQuizSession'
import DeleteIcon from '@mui/icons-material/Delete'
import { pocketbase } from '../lib/config'
import { useMemo, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import QuestionItemCount from '../components/QuestionItemCount'
import QuestionItems from '../components/QuestionItems'
import { UpdateQuestionData } from '../util/useQuestions'

const deleteQuizSession = async (id: string) => {
  return await pocketbase.collection('quiz_sessions').delete(id)
}

const QuizResultPage = () => {
  const { id: quizSessionId } = useParams<{ id: string }>()

  const {
    isLoading,
    isError,
    error,
    isSuccess,
    quiz,
    questions,
    useUpdateQuestion,
    useDeleteQuestion,
    upsertQuestionUserMeta,
    answers,
    totalPoints,
    maxTotalPoints,
    tags,
  } = useQuizSession({ id: quizSessionId as string })

  const percentageCorrect = Math.round((totalPoints / maxTotalPoints) * 100)

  const [showUnanswered, setShowUnanswered] = useState(false)
  const [deleteQuizSessionLoading, setDeleteQuizSessionLoading] =
    useState(false)

  const navigate = useNavigate()

  const handleDeleteQuizSession = async () => {
    if (!confirm('Are you sure you want to delete this quiz?')) {
      return
    }
    setDeleteQuizSessionLoading(true)
    await deleteQuizSession(quizSessionId as string)
    navigate('/quiz')
  }

  const {
    mutate: updateQuestion,
    isLoading: updateQuestionIsLoading,
    isSuccess: updateQuestionIsSuccess,
  } = useUpdateQuestion

  const handleUpdateQuestion = (
    questionId: string,
    data: UpdateQuestionData
  ) => {
    updateQuestion({ id: questionId, data })
  }

  const handleDeleteQuestion = (questionId: string) => {
    useDeleteQuestion.mutate(questionId)
  }

  const [selectedFilter, setSelectedFilter] = useState<{
    label: string
    value: string
  } | null>(null)

  const filters = [
    {
      label: 'Correct',
      value: 'correct',
    },
    {
      label: 'Incorrect',
      value: 'incorrect',
    },
    {
      label: 'Unanswered',
      value: 'unanswered',
    },
    {
      label: 'Bookmarked',
      value: 'marked',
    },
  ]

  const filteredQuestions = useMemo(
    () =>
      questions?.items.filter((question) => {
        const alternatives = question.expand?.alternatives
        const questionAnswer = answers[question.id]
        const alternativeIds = questionAnswer?.alternativeIds

        const questionAnswerPoints =
          alternatives !== undefined && alternativeIds !== undefined
            ? getQuestionAnswerPoints({
                alternatives,
                alternativeIds,
                points: question.points,
              })
            : undefined

        const questionIsUnanswered =
          questionAnswer === undefined ||
          (questionAnswer.alternativeIds?.length === 0 &&
            questionAnswer.text === '')

        if (selectedFilter?.value === 'correct') {
          return (
            questionAnswerPoints !== undefined &&
            questionAnswerPoints >= question.points
          )
        }
        if (selectedFilter?.value === 'incorrect') {
          return (
            questionAnswerPoints !== undefined &&
            questionAnswerPoints < question.points
          )
        }
        if (selectedFilter?.value === 'unanswered') {
          return questionIsUnanswered
        }
        if (selectedFilter?.value === 'marked') {
          return (
            question.expand?.['question_user_meta(question)']?.[0] !==
              undefined &&
            question.expand?.['question_user_meta(question)'][0].marked
          )
        }

        if (showUnanswered) {
          return true
        }
        return !questionIsUnanswered
      }) ?? [],
    [questions, answers, selectedFilter, showUnanswered]
  )

  const unansweredQuestions =
    questions?.items.filter(
      (question) =>
        answers[question.id] === undefined ||
        (answers[question.id].alternativeIds?.length === 0 &&
          answers[question.id].text === '')
    ) ?? []

  return (
    <>
      <AppBar position="static" color="inherit">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            {isSuccess && (
              <Breadcrumbs aria-label="breadcrumb" sx={{ py: 1 }}>
                <Link
                  component={RouterLink}
                  to="/quiz"
                  underline="hover"
                  color="inherit"
                >
                  Quiz
                </Link>
                <Typography color="text.primary" fontWeight={500}>
                  {quiz?.name || 'Quiz'}
                </Typography>
              </Breadcrumbs>
            )}
          </Box>

          <LoadingButton
            color="inherit"
            loading={deleteQuizSessionLoading}
            startIcon={<DeleteIcon />}
            onClick={handleDeleteQuizSession}
            sx={(theme) => ({
              ':hover': {
                color: theme.palette.error.main,
              },
              mr: 1,
            })}
          >
            Delete
          </LoadingButton>
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {isLoading && <CircularProgress />}

        {isError && (
          <Alert severity="error">
            Error fetching quiz: {(error as any).message}
          </Alert>
        )}

        {isSuccess && questions !== undefined && (
          <>
            <Alert
              severity={percentageCorrect >= 70 ? 'success' : 'info'}
              sx={{ mb: 2 }}
            >
              {totalPoints} / {maxTotalPoints} correct ({percentageCorrect} %)
            </Alert>

            {tags.length > 0 && (
              <Paper sx={{ p: 3, mb: 2 }}>
                {tags.map((tag) => (
                  <Box key={tag.id} sx={{ mb: 1 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 1,
                      }}
                    >
                      <Link
                        component={RouterLink}
                        to={`/search?tags=${tag.id}`}
                        underline="hover"
                        color="inherit"
                      >
                        {tag.name}
                      </Link>

                      <Typography sx={{ ml: 1 }}>
                        {tag.totalPoints} / {tag.questions.length}
                      </Typography>
                    </Box>

                    <LinearProgress
                      variant="determinate"
                      value={(tag.totalPoints / maxTotalPoints) * 100}
                    ></LinearProgress>
                  </Box>
                ))}
              </Paper>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Autocomplete
                  disablePortal
                  options={filters}
                  value={selectedFilter}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(event, newValue) => {
                    setSelectedFilter(newValue)
                  }}
                  size="small"
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Filter" />
                  )}
                />

                {selectedFilter !== null && (
                  <Button
                    onClick={() => setSelectedFilter(null)}
                    sx={{ ml: 1 }}
                  >
                    Clear filter
                  </Button>
                )}
              </Box>

              {unansweredQuestions.length > 0 && selectedFilter === null && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showUnanswered}
                        onChange={(event, checked) =>
                          setShowUnanswered(checked)
                        }
                      />
                    }
                    label="Show unanswered questions"
                    labelPlacement="start"
                  />
                </FormGroup>
              )}
            </Box>

            <Box sx={{ mb: 2 }}>
              {filteredQuestions.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                    mb: 2,
                  }}
                >
                  <QuestionItemCount
                    count={
                      selectedFilter !== null
                        ? filteredQuestions.length
                        : questions.items.length
                    }
                  />
                </Box>
              )}

              <QuestionItems
                questions={filteredQuestions}
                answers={answers}
                showAnswer
                updateQuestionIsLoading={updateQuestionIsLoading}
                updateQuestionIsSuccess={updateQuestionIsSuccess}
                onUpdateQuestion={handleUpdateQuestion}
                onDeleteQuestion={handleDeleteQuestion}
                onQuestionUserMetaChange={(id, data) =>
                  upsertQuestionUserMeta({ id, data })
                }
              />
            </Box>

            {!showUnanswered &&
              unansweredQuestions.length > 0 &&
              selectedFilter === null && (
                <Typography>
                  {unansweredQuestions.length} unanswered questions are hidden.
                  <Button onClick={() => setShowUnanswered(true)}>
                    Show unanswered questions
                  </Button>
                </Typography>
              )}
          </>
        )}
      </Container>
    </>
  )
}

export default QuizResultPage
