import {
  Alert,
  AppBar,
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import DownloadIcon from '@mui/icons-material/FileDownload'
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom'
import PaginatedQuestions from '../components/PaginatedQuestions'
import useTag from '../util/useTag'
import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { pocketbase } from '../lib/config'
import TagDialog from '../components/TagDialog'
import { downloadQuestions } from '../util/useDownload'

const updateTag = async (id: string, data: { name: string }) => {
  return await pocketbase.collection('tags').update(id, data)
}

const deleteTag = async (id: string) => {
  return await pocketbase.collection('tags').delete(id)
}

const TagPage = () => {
  const { id: tagId } = useParams<{ id: string }>()

  const {
    isLoading,
    data: tag,
    isError,
    error,
    isSuccess,
    refetch: refetchTag,
  } = useTag(tagId as string)

  const [tagDialogOpen, setTagDialogOpen] = React.useState(false)
  const [isLoadingDeleteFolder, setIsLoadingDeleteFolder] =
    React.useState(false)
  const [isLoadingDownloadQuestions, setIsLoadingDownloadQuestions] =
    React.useState(false)

  const queryClient = useQueryClient()

  const handleUpdateTag = async (data: any) => {
    await updateTag(tagId as string, data)
    refetchTag()
    queryClient.invalidateQueries(['tags'])
    setTagDialogOpen(false)
  }

  const navigate = useNavigate()

  const handleDeleteTag = async () => {
    if (!confirm('Are you sure you want to delete this tag?')) {
      return
    }
    setIsLoadingDeleteFolder(true)
    await deleteTag(tagId as string)
    queryClient.invalidateQueries(['folders'])
    navigate('/tags')
  }

  const handleDownloadQuestions = async () => {
    if (!tag) return
    setIsLoadingDownloadQuestions(true)
    await downloadQuestions({ tagId: tag.id }, tag.name)
    setIsLoadingDownloadQuestions(false)
  }

  return (
    <>
      {isSuccess && (
        <TagDialog
          tag={tag as any}
          open={tagDialogOpen}
          onClose={() => setTagDialogOpen(false)}
          onSubmit={handleUpdateTag}
        />
      )}
      <AppBar position="static" color="inherit">
        <Toolbar>
          {isSuccess && (
            <>
              <Box sx={{ flexGrow: 1 }}>
                {isSuccess && (
                  <Breadcrumbs aria-label="breadcrumb" sx={{ py: 1 }}>
                    <Link
                      component={RouterLink}
                      to="/tags"
                      underline="hover"
                      color="inherit"
                    >
                      Tags
                    </Link>

                    <Typography color="text.primary" fontWeight={500}>
                      {tag.name}
                    </Typography>
                  </Breadcrumbs>
                )}
              </Box>

              <Box sx={{ mr: 1 }}>
                <IconButton
                  color="inherit"
                  onClick={() => setTagDialogOpen(true)}
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  color="inherit"
                  onClick={handleDeleteTag}
                  sx={(theme: any) => ({
                    ':hover': {
                      color: theme.palette.error.main,
                    },
                  })}
                >
                  {isLoadingDeleteFolder ? (
                    <CircularProgress size={20} />
                  ) : (
                    <DeleteIcon />
                  )}
                </IconButton>

                <IconButton color="inherit" onClick={handleDownloadQuestions}>
                  {isLoadingDownloadQuestions ? (
                    <CircularProgress size={24} />
                  ) : (
                    <DownloadIcon />
                  )}
                </IconButton>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {isLoading && <CircularProgress />}

        {isError && (
          <Alert severity="error">
            Error fetching tag: {(error as any).message}
          </Alert>
        )}

        {isSuccess && <PaginatedQuestions tagIds={[tagId as string]} />}
      </Container>
    </>
  )
}

export default TagPage
