import {
  Alert,
  Box,
  Card,
  CardContent,
  Chip,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import React from 'react'
import ImageLightbox from './ImageLightbox'
import AutocompleteTags from './AutocompleteTags'
import { useFormik } from 'formik'
import { pocketbase } from '../lib/config'
import { Collection, Tag } from '../types/global'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import DOMPurify from 'dompurify'
import { transformTags } from '../util/transformTags'

type QuestionCardProps = {
  text: string
  images?: string[]
  alternatives?: {
    id?: string
    text: string
    correct: boolean
    explanation?: string
  }[]
  tags?: Tag[]
  collection?: Collection
  tagOptions?: Tag[]
  explanation?: string
  answer?: {
    alternativeIds?: string[]
    text?: string
  }
  showAnswer?: boolean
  editTags?: boolean
  highlightQuery?: string
  imagesFull?: boolean
  onUpdateQuestion?: (data: { tags: Tag[] }) => void
}

const QuestionCard: React.FC<QuestionCardProps> = (props) => {
  const [lightboxOpen, setLightboxOpen] = React.useState<boolean>(false)
  const [lightboxImageIndex, setLightboxImageIndex] = React.useState<number>()

  const openLightbox = (index: number) => {
    setLightboxImageIndex(index)
    setLightboxOpen(true)
  }

  const highlightQuery = (str: string, query?: string) => {
    if (!query) return str
    let startIndex = 0
    let index: number
    while (
      (index = str
        .toLocaleLowerCase()
        .indexOf(query.toLowerCase(), startIndex)) > -1
    ) {
      const insert = `<span class="bg-fuchsia-500 text-white">${str.substring(
        index,
        index + query.length
      )}</span>`
      str =
        str.substring(0, index) + insert + str.substring(index + query.length)
      startIndex = index + insert.length
    }
    return str
  }

  const questionText = highlightQuery(
    DOMPurify.sanitize(props.text),
    props.highlightQuery
  )

  const questionExplanation = props.explanation
    ? DOMPurify.sanitize(props.explanation)
    : undefined

  const formik = useFormik({
    initialValues: {
      tags: props.tags ?? [],
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const userId = pocketbase.authStore.model?.id
      if (!userId) return
      const tags = await transformTags(values.tags, userId)
      props.onUpdateQuestion?.({ tags })
    },
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const navigate = useNavigate()

  const handleTagClicked = (tag: Tag) => {
    const tagIds = searchParams.get('tags')?.split(',')
    const newTagIds = tagIds
      ? [...tagIds.filter((id) => id !== tag.id), tag.id]
      : [tag.id]
    searchParams.set('tags', newTagIds.join(','))

    if (location.pathname === '/search') {
      setSearchParams(searchParams)
    } else {
      navigate(`/search?${searchParams.toString()}`)
    }
  }

  return (
    <Card>
      {props.images && props.images.length > 0 && lightboxOpen && (
        <ImageLightbox
          images={props.images}
          index={lightboxImageIndex}
          onClose={() => setLightboxOpen(false)}
        />
      )}

      <CardContent>
        {props.editTags ? (
          <Box sx={{ mb: 1 }}>
            <AutocompleteTags
              options={props.tagOptions}
              tags={formik.values.tags}
              autoFocus
              variant="standard"
              size="small"
              onChange={(value) => formik.setFieldValue('tags', value)}
              onBlur={formik.submitForm}
            />
          </Box>
        ) : (
          (props.tags || props.collection) && (
            <Stack direction="row" spacing={0.5} sx={{ mb: 1 }}>
              {props.collection && (
                <Chip
                  label={props.collection.name}
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={(e) =>
                    navigate(`/collection/${props.collection?.id}`)
                  }
                />
              )}
              {props.tags?.map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  size="small"
                  onClick={(e) => handleTagClicked(tag)}
                />
              ))}
            </Stack>
          )
        )}

        <div className="prose max-w-none">
          <span dangerouslySetInnerHTML={{ __html: questionText }}></span>
        </div>
        {props.images &&
          props.images.length > 0 &&
          (!props.imagesFull ? (
            <ImageList cols={8} sx={{ mt: 1.5 }}>
              {props.images.map((value, index) => (
                <ImageListItem
                  key={index}
                  onClick={() => openLightbox(index)}
                  sx={{ cursor: 'pointer' }}
                >
                  <img
                    src={
                      value.startsWith('http')
                        ? `${value}?thumb=100x100`
                        : value
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <Box sx={{ mt: 2 }}>
              {props.images.map((value) => (
                <img src={value} />
              ))}
            </Box>
          ))}
        {props.alternatives && (
          <List dense>
            {props.alternatives?.map((alternative, index) => {
              const selected =
                !!alternative.id &&
                !!props.answer?.alternativeIds?.includes(alternative.id)

              const alternativeText = highlightQuery(
                DOMPurify.sanitize(alternative.text),
                props.highlightQuery
              )

              const alternativeExplanation = alternative.explanation
                ? DOMPurify.sanitize(alternative.explanation)
                : undefined

              return (
                <ListItem key={index}>
                  {props.showAnswer && (
                    <QuestionAlternativeListItemIcon
                      correct={alternative.correct}
                      selected={selected}
                    />
                  )}
                  <ListItemText>
                    <Typography
                      fontWeight={
                        props.showAnswer && selected ? '700' : undefined
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: alternativeText }}
                      ></span>
                    </Typography>

                    {alternativeExplanation && props.showAnswer && (
                      <Alert severity="info" variant="outlined" sx={{ mt: 1 }}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: alternativeExplanation,
                          }}
                        ></span>
                      </Alert>
                    )}
                  </ListItemText>
                </ListItem>
              )
            })}
          </List>
        )}
        {questionExplanation && props.showAnswer && (
          <Alert severity="info" sx={{ mt: 1 }}>
            <span
              dangerouslySetInnerHTML={{ __html: questionExplanation }}
            ></span>
          </Alert>
        )}
      </CardContent>
    </Card>
  )
}

const QuestionAlternativeListItemIcon: React.FC<{
  correct: boolean
  selected: boolean
}> = ({ correct, selected }) => {
  if (correct && selected) {
    return (
      <ListItemIcon>
        <CheckCircleIcon color="success" />
      </ListItemIcon>
    )
  } else if (correct) {
    return (
      <ListItemIcon>
        <CheckCircleOutlineIcon color="success" />
      </ListItemIcon>
    )
  } else if (selected) {
    return (
      <ListItemIcon>
        <RadioButtonCheckedIcon color="error" />
      </ListItemIcon>
    )
  }

  return <></>
}

export default QuestionCard
