import {
  Typography,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  CircularProgress,
  Alert,
  Container,
  AppBar,
  Box,
  Breadcrumbs,
  Button,
  Toolbar,
  IconButton,
} from '@mui/material'
import { pocketbase } from '../lib/config'
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import IosShareIcon from '@mui/icons-material/IosShare'
import QuizIcon from '@mui/icons-material/Quiz'
import React from 'react'
import QuizDialog from '../components/QuizDialog'
import useFolder from '../util/useFolder'
import FolderDialog from '../components/FolderDialog'
import CollectionDialog from '../components/CollectionDialog'
import useCollections from '../util/useCollections'
import ExportDialog from '../components/ExportDialog'
import { useQueryClient } from '@tanstack/react-query'
import useTags from '../util/useTags'

const updateFolder = async (id: string, data: { name: string }) => {
  return await pocketbase.collection('folders').update(id, data)
}

const deleteFolder = async (id: string) => {
  return await pocketbase.collection('folders').delete(id)
}

interface CreateCollectionData {
  folder: string
  name: string
}

const createCollection = async (data: CreateCollectionData) => {
  return await pocketbase.collection('collections').create(data)
}

const FolderPage = () => {
  const { id: folderId } = useParams<{ id: string }>()

  const {
    isLoading,
    data: folder,
    isError,
    error,
    isSuccess,
    refetch: refetchFolder,
  } = useFolder(folderId as string)

  const { data: collections, refetch: refetchCollections } = useCollections({
    folderId,
  })

  const { data: tags } = useTags()

  const [quizDialogOpen, setQuizDialogOpen] = React.useState(false)
  const [folderDialogOpen, setFolderDialogOpen] = React.useState(false)
  const [collectionDialogOpen, setCollectionDialogOpen] = React.useState(false)
  const [exportDialogOpen, setExportDialogOpen] = React.useState(false)
  const [isLoadingDeleteFolder, setIsLoadingDeleteFolder] =
    React.useState(false)

  const handleUpdateFolder = async (data: any) => {
    await updateFolder(folderId as string, data)
    refetchFolder()
    queryClient.invalidateQueries(['folders'])
    setFolderDialogOpen(false)
  }

  const queryClient = useQueryClient()

  const navigate = useNavigate()

  const handleDeleteFolder = async () => {
    if (!confirm('Are you sure you want to delete this folder?')) {
      return
    }
    setIsLoadingDeleteFolder(true)
    await deleteFolder(folderId as string)
    queryClient.invalidateQueries(['folders'])
    navigate('/')
  }

  const handleCreateCollection = async (data: { name: string }) => {
    await createCollection({ name: data.name, folder: folderId as string })
    refetchCollections()
    setCollectionDialogOpen(false)
  }

  return (
    <>
      {isSuccess && (
        <>
          <FolderDialog
            folder={folder as any}
            open={folderDialogOpen}
            onClose={() => setFolderDialogOpen(false)}
            onSubmit={handleUpdateFolder}
          />

          <CollectionDialog
            open={collectionDialogOpen}
            submitLabel="Create collection"
            onClose={() => setCollectionDialogOpen(false)}
            onSubmit={handleCreateCollection}
          />

          <ExportDialog
            folder={folder as any}
            open={exportDialogOpen}
            onClose={() => setExportDialogOpen(false)}
          />

          <QuizDialog
            open={quizDialogOpen}
            onClose={() => setQuizDialogOpen(false)}
            folderId={folderId as string}
            collections={collections}
            tags={tags}
          />
        </>
      )}

      <AppBar position="static" color="inherit">
        <Toolbar>
          {isSuccess && (
            <>
              <Box sx={{ flexGrow: 1 }}>
                {isSuccess && (
                  <Breadcrumbs aria-label="breadcrumb" sx={{ py: 1 }}>
                    <Typography color="text.primary" fontWeight={500}>
                      {folder.name}
                    </Typography>
                  </Breadcrumbs>
                )}
              </Box>

              <Box sx={{ mr: 1 }}>
                <IconButton
                  color="inherit"
                  onClick={() => setFolderDialogOpen(true)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  onClick={handleDeleteFolder}
                  sx={(theme: any) => ({
                    ':hover': {
                      color: theme.palette.error.main,
                    },
                  })}
                >
                  {isLoadingDeleteFolder ? (
                    <CircularProgress size={20} />
                  ) : (
                    <DeleteIcon />
                  )}
                </IconButton>
              </Box>
              <Button
                color="inherit"
                startIcon={<IosShareIcon />}
                onClick={() => setExportDialogOpen(true)}
                sx={{ mr: 1 }}
              >
                Export
              </Button>
              <Button
                startIcon={<QuizIcon />}
                onClick={() => setQuizDialogOpen(true)}
              >
                Quiz
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {isLoading && <CircularProgress />}

        {isError && (
          <Alert severity="error">
            Error fetching folder: {(error as any).message}
          </Alert>
        )}

        {isSuccess && (
          <>
            <Paper sx={{ mb: 2 }}>
              <List subheader={<ListSubheader>Collections</ListSubheader>}>
                {collections?.map((collection) => (
                  <ListItemButton
                    key={collection.id}
                    component={RouterLink}
                    to={`/collection/${collection.id}`}
                  >
                    <ListItemText primary={collection.name} />
                  </ListItemButton>
                ))}
              </List>

              <Box sx={{ px: 1, pb: 2 }}>
                <Button onClick={() => setCollectionDialogOpen(true)}>
                  New collection
                </Button>
              </Box>
            </Paper>
          </>
        )}
      </Container>
    </>
  )
}

export default FolderPage
