import { Typography } from '@mui/material'

const QuestionItemCount = (props: { count: number }) => {
  return (
    <Typography variant="body2">
      {props.count}{' '}
      {props.count === 0 || props.count > 1 ? 'questions' : 'question'}
    </Typography>
  )
}

export default QuestionItemCount
