import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { Collection } from '../types/global'
import useFolders from '../util/useFolders'

type CollectionDialogProps = {
  collection?: Collection
  selectFolder?: boolean
  open: boolean
  submitLabel?: string
  onClose: () => void
  onSubmit?: (data: any) => void
}

const CollectionDialog: React.FC<CollectionDialogProps> = ({
  collection,
  selectFolder = false,
  open,
  submitLabel,
  onClose,
  onSubmit,
}) => {
  const { data: folders } = useFolders({ enabled: open })

  const formik = useFormik({
    initialValues: {
      name: collection?.name ?? '',
      folder: folders?.find((f) => f.id === collection?.folder) ?? null,
    },
    validate: (values) => {
      const errors: any = {}
      if (!values.name) {
        errors.name = 'Name is required'
      }
      if (selectFolder && !values.folder) {
        errors.folder = 'Folder is required'
      }
      return errors
    },
    onSubmit: (values) => {
      onSubmit?.({
        name: values.name,
        folder: values.folder?.id,
      })
    },
  })

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box>
            <TextField
              autoFocus
              fullWidth
              label="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />

            {formik.errors.name && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {formik.errors.name}
              </Typography>
            )}
          </Box>

          {selectFolder && (
            <Box sx={{ mt: 3 }}>
              <Autocomplete
                sx={{ flexGrow: 1 }}
                options={folders || []}
                value={formik.values.folder}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, value) => {
                  formik.setFieldValue('folder', value)
                }}
                getOptionLabel={(option) => option.name as string}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      <Typography>{option.name}</Typography>
                    </li>
                  )
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Folder" />
                )}
              />

              {formik.errors.folder && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {formik.errors.folder}
                </Typography>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">{submitLabel ?? 'Submit'}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CollectionDialog
