import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Button,
  Typography,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { POCKETBASE_URL } from '../lib/config'
import { useState } from 'react'

type QuestionImagesInputProps = {
  images: string[]
  selectedImageFiles: File[]
  questionId?: string
  onChange?: (images: string[]) => void
  onSelectedChange?: (files: File[]) => void
}

const QuestionImagesInput: React.FC<QuestionImagesInputProps> = ({
  images,
  selectedImageFiles,
  questionId,
  onChange,
  onSelectedChange,
}) => {
  const [error, setError] = useState<string | null>(null)

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files)

      const maxSize = 5242880 // 5MB
      const tooLargeFiles = files.filter((f) => f.size > maxSize)
      if (tooLargeFiles.length > 0) {
        setError(
          `File too large: ${tooLargeFiles
            .map((f) => f.name)
            .join(', ')} (max 5MB)`
        )
        return
      } else {
        setError(null)
      }

      onSelectedChange?.([...selectedImageFiles, ...files])
    }
  }

  const handleRemoveSelectedImage = (file: File) => {
    onSelectedChange?.(selectedImageFiles?.filter((f) => f !== file))
  }

  const handleRemoveImage = (filename: string) => {
    onChange?.(images?.filter((f) => f !== filename))
  }
  return (
    <>
      {(images.length > 0 || selectedImageFiles.length > 0) && (
        <ImageList cols={4} sx={{ mb: 1 }}>
          {/* Initial question images */}
          {images?.map((filename) => (
            <ImageListItem key={filename}>
              <ImageListItemBar
                position="top"
                actionIcon={
                  <IconButton
                    onClick={() => handleRemoveImage(filename)}
                    sx={{
                      color: 'rgba(255, 255, 255, 0.8)',
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                }
                sx={{ background: 'transparent' }}
              />
              <img
                src={`${POCKETBASE_URL}/api/files/questions/${questionId}/${filename}`}
                alt={filename}
                style={{ width: '100%' }}
              />
            </ImageListItem>
          ))}

          {/* Newly selected images */}
          {selectedImageFiles &&
            selectedImageFiles.length > 0 &&
            selectedImageFiles.map((file) => (
              <ImageListItem key={file.name}>
                <ImageListItemBar
                  position="top"
                  actionIcon={
                    <IconButton
                      onClick={() => handleRemoveSelectedImage(file)}
                      sx={{
                        color: 'rgba(255, 255, 255, 0.8)',
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                  sx={{ background: 'transparent' }}
                />
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  style={{ width: '100%' }}
                />
              </ImageListItem>
            ))}
        </ImageList>
      )}

      {error && (
        <Typography variant="body2" color="error" sx={{ my: 1 }}>
          {error}
        </Typography>
      )}

      <Button component="label">
        Add images
        <input
          hidden
          accept="image/*"
          multiple
          type="file"
          onChange={handleFileSelect}
        />
      </Button>
    </>
  )
}

export default QuestionImagesInput
