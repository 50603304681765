import { Alternative } from '../types/global'
import { createAlternative, updateAlternative } from './useAlternatives'

export const transformAlternatives = async (
  data: {
    id: string
    text: string
    explanation: string
    hasExplanation: boolean
    correct: boolean
  }[],
  previousData: Alternative[] | undefined
) => {
  const alternatives: Alternative[] = []

  const updatedAlternatives = data.filter((alternative) =>
    previousData?.find((a) => a.id === alternative.id)
  )
  for (let i = 0; i < updatedAlternatives.length; i++) {
    const alternative = updatedAlternatives[i]
    const updatedAlternative = await updateAlternative(alternative.id, {
      text: alternative.text,
      explanation: alternative.hasExplanation ? alternative.explanation : '',
      correct: alternative.correct,
    })
    alternatives.push(updatedAlternative)
  }

  const newAlternatives = data.filter(
    (alternative) => !previousData?.find((a) => a.id === alternative.id)
  )
  for (let i = 0; i < newAlternatives.length; i++) {
    const { text, hasExplanation, explanation, correct } = newAlternatives[i]
    const alternative = await createAlternative({
      text,
      explanation: hasExplanation ? explanation : undefined,
      correct,
    })
    alternatives.push(alternative)
  }

  // TODO: Delete alternatives when database structure discussion is resolved
  // const deletedAlternatives = (previousData ?? []).filter(
  //   (alternative) => !data.find((a) => a.id === alternative.id)
  // )
  // if (deletedAlternatives !== undefined && deletedAlternatives.length > 0) {
  //   for (let i = 0; i < deletedAlternatives?.length; i++) {
  //     await deleteAlternative(deletedAlternatives?.[i].id)
  //   }
  // }

  return alternatives
}
