import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { pocketbase } from '../lib/config'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Alert, CircularProgress, Stack } from '@mui/material'

const handleRegister = async (email: string, password: string) => {
  return await pocketbase
    .collection('users')
    .create({ email, password, passwordConfirm: password })
}

export default function RegisterPage() {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])

  const handleError = (error: any) => {
    if (error.data.code === 400) {
      if (error.data.data.email !== undefined) {
        setErrors([
          ...errors.filter((e) => e !== error.data.data.email.message),
          error.data.data.email.message,
        ])
      }
      if (error.data.data.password !== undefined) {
        if (error.data.data.password.code === 'validation_length_out_of_range')
          setErrors([
            ...errors.filter(
              (e) => e !== 'Password must be at least 8 characters long'
            ),
            'Password must be at least 8 characters long',
          ])
      }
    } else {
      setErrors([...errors.filter((e) => e !== error.message), error.message])
    }
  }

  const navigate = useNavigate()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    setLoading(true)
    try {
      await handleRegister(
        data.get('email') as string,
        data.get('password') as string
      )
      navigate('/login?registered=true')
    } catch (error: any) {
      handleError(error)
    }
    setLoading(false)
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <HowToRegIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create account
        </Typography>

        {!loading && errors && errors.length > 0 && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <Stack>
              {errors.map((error, index) => (
                <Typography key={index}>{error}</Typography>
              ))}
            </Stack>
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Register'
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} variant="body2" to="/login">
                Already have an account? Log in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}
