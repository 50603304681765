import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Pagination,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import useQuestions, { UpdateQuestionData } from '../util/useQuestions'
import SearchIcon from '@mui/icons-material/Search'
import QuestionItemCount from './QuestionItemCount'
import QuestionItems from './QuestionItems'

type PaginatedQuestionsProps = {
  collectionIds?: string[]
  tagIds?: string[]
  query?: string
  disableSearch?: boolean
  showCollection?: boolean
}

const PaginatedQuestions: React.FC<PaginatedQuestionsProps> = (props) => {
  const [page, setPage] = useState(1)
  const [perPage] = useState(10)
  const [query, setQuery] = useState(props.query)

  useEffect(() => {
    // Reset page to 1 when query or tags change
    // Causes requests for other pages to be autocancelled by pocketbase SDK
    // TODO: Is there a better solution?
    if (query !== undefined) {
      setPage(1)
    }
  }, [query, props.query, props.tagIds])

  useEffect(() => {
    if (props.query) {
      setQuery(props.query)
    }
  }, [props.query])

  const {
    isLoading,
    isError,
    error,
    data,
    isSuccess,
    useUpdateQuestion,
    useDeleteQuestion,
    upsertQuestionUserMeta,
    isRefetching,
  } = useQuestions({
    page,
    perPage,
    collectionIds: props.collectionIds,
    tagIds: props.tagIds,
    query,
    expand: props.showCollection
      ? 'alternatives,tags,question_user_meta(question),collection'
      : 'alternatives,tags,question_user_meta(question)',
  })

  const {
    mutate: updateQuestion,
    isLoading: updateQuestionIsLoading,
    isSuccess: updateQuestionIsSuccess,
  } = useUpdateQuestion

  const handleUpdateQuestion = (
    questionId: string,
    data: UpdateQuestionData
  ) => {
    updateQuestion({ id: questionId, data })
  }

  const handleDeleteQuestion = (questionId: string) => {
    useDeleteQuestion.mutate(questionId)
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value)
  }

  if (isLoading) {
    return <CircularProgress />
  }

  if (isError) {
    return (
      <Alert severity="error">
        Error fetching questions: {(error as any).message}
      </Alert>
    )
  }

  if (isSuccess) {
    return (
      <Box>
        <Grid
          container
          spacing={3}
          sx={{
            mb: 2,
          }}
        >
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
            }}
          >
            {data.totalItems > 0 && (
              <QuestionItemCount count={data.totalItems} />
            )}
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={(theme) => ({
              display: 'flex',
              [theme.breakpoints.up('md')]: {
                justifyContent: 'center',
              },
              justifyContent: props.disableSearch ? 'left' : undefined,
            })}
          >
            {data.totalPages > 1 && (
              <Pagination
                page={page}
                count={data.totalPages}
                onChange={handlePageChange}
              />
            )}
          </Grid>

          {!props.disableSearch && (
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              sx={{ display: 'flex', justifyContent: 'end' }}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      setQuery?.(e.currentTarget.value)
                    }
                  },
                }}
                variant="standard"
              />
            </Grid>
          )}
        </Grid>

        {isRefetching ? (
          <CircularProgress />
        ) : (
          <QuestionItems
            questions={data.items}
            highlightQuery={query}
            updateQuestionIsLoading={updateQuestionIsLoading}
            updateQuestionIsSuccess={updateQuestionIsSuccess}
            onUpdateQuestion={handleUpdateQuestion}
            onDeleteQuestion={handleDeleteQuestion}
            onQuestionUserMetaChange={(id, data) =>
              upsertQuestionUserMeta({ id, data })
            }
          />
        )}

        {data.totalPages > 1 && !isRefetching && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              my: 3,
              [theme.breakpoints.up('md')]: {
                justifyContent: 'center',
              },
            })}
          >
            <Pagination
              page={page}
              count={data.totalPages}
              onChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
    )
  }

  return <></>
}

export default PaginatedQuestions
