import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import BasicDatePicker from '../components/BasicDatePicker'
import { pocketbase } from '../lib/config'
import { Answer, Collection, Folder, Question, Tag } from '../types/global'
import useCollections from '../util/useCollections'
import useFolders from '../util/useFolders'
import { getTotalPoints } from '../util/useQuizSession'
import useTags from '../util/useTags'

const fetchAnswers = async ({ queryKey }: any) => {
  const { folderId, collectionIds, tagIds, startDate, endDate } =
    queryKey[1] as {
      folderId?: string
      collectionIds: string[]
      tagIds: string[]
      startDate: Date
      endDate: Date
    }
  let filter = `created>="${startDate.toISOString()}"&&updated<"${endDate.toISOString()}"`
  if (folderId) {
    filter += `&&question.collection.folder="${folderId}"`
  }
  if (collectionIds.length > 0) {
    filter += `&&(${collectionIds
      .map((id) => `question.collection="${id}"`)
      .join('||')})`
  }
  if (tagIds.length > 0) {
    filter += `&&(${tagIds.map((id) => `question.tags.id="${id}"`).join('||')})`
  }
  return await pocketbase.collection('answers').getFullList<Answer>(undefined, {
    filter,
    expand: 'question,question.alternatives,alternatives',
  })
}

const StatisticsPage = () => {
  const formik = useFormik<{
    folder: Folder | null
    collections: Collection[]
    tags: Tag[]
    startDate: Date
    endDate: Date
  }>({
    initialValues: {
      folder: null,
      collections: [],
      tags: [],
      // two weeks ago
      startDate: new Date(new Date().setDate(new Date().getDate() - 14)),
      endDate: new Date(),
    },
    validate: (values) => {
      const errors: any = {}
      if (!values.folder) {
        errors.folder = 'Folder is required'
      }
      return errors
    },
    // fetch is done in useQuery on enabled
    onSubmit: async (values) => {
      console.log(values)
    },
  })

  const { data: folders } = useFolders()
  const { data: collections } = useCollections({
    folderId: formik.values.folder?.id,
  })
  const { data: tags } = useTags()

  const queryKey = [
    'answers',
    {
      folderId: formik.values.folder?.id,
      collectionIds: formik.values.collections.map((c) => c.id),
      tagIds: formik.values.tags.map((t) => t.id),
      startDate: formik.values.startDate,
      endDate: formik.values.endDate,
    },
  ]

  const { data: answers, isSuccess } = useQuery(queryKey, fetchAnswers, {
    enabled: formik.isSubmitting && formik.values.folder !== null,
    staleTime: 1000 * 60,
  })

  const generalStatistics = useMemo(() => {
    if (answers === undefined) return null
    const questions = answers.map((answer) => answer.expand?.question!)
    const totalPoints = getTotalPoints(
      questions.map((question) => {
        const questionAnswer = answers.find(
          (answer) => answer.question === question.id
        )
        return {
          ...question,
          alternatives: question.expand?.alternatives ?? [],
          answer: {
            alternativeIds: questionAnswer?.alternatives ?? [],
          },
        }
      })
    )
    return {
      answers,
      totalPoints,
    }
  }, [answers])

  const generalPercentage = useMemo(() => {
    return generalStatistics && generalStatistics.answers.length > 0
      ? (generalStatistics.totalPoints / generalStatistics.answers.length) * 100
      : 0
  }, [generalStatistics])

  const tagStatistics = useMemo(() => {
    if (answers === undefined) return null
    return formik.values.tags?.map((tag) => {
      const tagAnswers = answers.filter((answer) =>
        answer.expand?.question?.tags.includes(tag.id)
      )
      const tagQuestions = tagAnswers.map((answer) => answer.expand?.question!)
      const totalPoints = getTotalPoints(
        tagQuestions.map((question) => {
          const questionAnswer = tagAnswers.find(
            (answer) => answer.question === question.id
          )
          return {
            ...question,
            alternatives: question.expand?.alternatives ?? [],
            answer: {
              alternativeIds: questionAnswer?.alternatives ?? [],
            },
          }
        })
      )
      return {
        tag,
        answers: tagAnswers,
        totalPoints,
      }
    })
  }, [answers])

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <div>
              <Autocomplete
                disablePortal
                options={folders ?? []}
                getOptionLabel={(option) => option.name}
                value={formik.values.folder}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                  formik.setFieldValue('folder', newValue)
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Folder" />
                )}
              />

              {formik.errors.folder && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {formik.errors.folder}
                </Typography>
              )}
            </div>

            <Autocomplete
              disablePortal
              multiple
              options={collections ?? []}
              getOptionLabel={(option) => option.name}
              value={formik.values.collections}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                formik.setFieldValue('collections', newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} label="Collections" />
              )}
            />

            <Autocomplete
              disablePortal
              multiple
              options={tags ?? []}
              getOptionLabel={(option) => option.name}
              value={formik.values.tags}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                formik.setFieldValue('tags', newValue)
              }}
              renderInput={(params) => <TextField {...params} label="Tags" />}
            />

            <Stack direction="row" spacing={2}>
              <BasicDatePicker
                value={formik.values.startDate}
                label="From"
                onChange={(newValue) =>
                  formik.setFieldValue('startDate', newValue)
                }
              ></BasicDatePicker>
              <BasicDatePicker
                value={formik.values.endDate}
                label="To"
                onChange={(newValue) =>
                  formik.setFieldValue('endDate', newValue)
                }
              ></BasicDatePicker>
            </Stack>

            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button type="submit">Submit</Button>
            </Box>
          </Stack>
        </form>
      </Paper>

      {isSuccess &&
        (answers && answers.length > 0 ? (
          <>
            <Paper sx={{ p: 3, mb: 2 }}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 1,
                  }}
                >
                  <Typography>Total answers</Typography>
                  <Typography>
                    {generalStatistics?.totalPoints} /{' '}
                    {generalStatistics?.answers.length}{' '}
                    {generalPercentage > 0 &&
                      ` (${generalPercentage.toFixed(2)}%)`}
                  </Typography>
                </Box>
              </Box>

              <LinearProgress
                variant="determinate"
                value={generalPercentage}
              ></LinearProgress>
            </Paper>

            {tagStatistics && tagStatistics.length > 0 && (
              <Paper sx={{ p: 3, mb: 2 }}>
                {tagStatistics.map((tag) => {
                  const percentage =
                    tag.answers.length > 0
                      ? (tag.totalPoints / tag.answers.length) * 100
                      : 0
                  return (
                    <Box key={tag.tag.id} sx={{ mb: 1 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mb: 1,
                        }}
                      >
                        <Typography>{tag.tag.name}</Typography>

                        <Typography sx={{ ml: 1 }}>
                          {tag.totalPoints} / {tag.answers.length}
                          {percentage > 0 && ` (${percentage.toFixed(2)}%)`}
                        </Typography>
                      </Box>

                      <LinearProgress
                        variant="determinate"
                        value={percentage}
                      ></LinearProgress>
                    </Box>
                  )
                })}
              </Paper>
            )}
          </>
        ) : (
          <Alert severity="info">No answers found</Alert>
        ))}
    </Container>
  )
}

export default StatisticsPage
